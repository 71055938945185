import { UserPortalsListItem } from '@/features/auth/types'
import { INavigationSubItem } from '@/types/interfaces/ui'
import { navigationItems } from '@/utils/data/navigationItems'
import { withParams } from '@/api'

const getAvailableLinksPerPortal = (
  selectedPortal: UserPortalsListItem | undefined,
  gateId: string
) =>
  selectedPortal?.id && selectedPortal?.permissions
    ? navigationItems.reduce((links: string[], navItem) => {
        const visible = navItem.visible(
          selectedPortal.type,
          selectedPortal.permissions
        )

        if (!visible) return links

        if (navItem.link) {
          links.push(
            withParams(navItem.link, {
              site_id: selectedPortal.id,
              gate_id: gateId
            })
          )
        }

        if (Array.isArray(navItem.items)) {
          const allowedSubItems: INavigationSubItem[] = navItem.items.filter(
            (item) => item.visible(selectedPortal.permissions)
          )

          links.push(
            ...allowedSubItems.map((item) =>
              withParams(item.link, {
                site_id: selectedPortal.id,
                gate_id: gateId
              })
            )
          )
        }

        return links
      }, [])
    : []

export default getAvailableLinksPerPortal
