import {
  AppointmentTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'
import { isNotVisitor } from '@/features/gate/utils'

const isCargoAssetAllowed = (
  appointmentType: AppointmentTypes,
  powerUnitType: PowerUnitTypes | undefined
) => {
  const notVisitor = isNotVisitor(appointmentType)
  const notVehicle = powerUnitType !== PowerUnitTypes.PersonalVehicle

  return notVisitor && notVehicle
}

export default isCargoAssetAllowed
