import { FC, useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'
import { useDebounceValue } from 'usehooks-ts'

import { Autocomplete, ReadOnlyFormValue } from '@/components/atoms'
import { DEBOUNCE_TIME } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import {
  useGetCarriersQuery,
  useLazyGetCarrierQuery
} from '@/features/gate/api'
import { Carrier } from '@/features/gate/types'
import { useStore } from '@/store'
import { ReadOnlyProps } from '@/types/interfaces/ui'

interface IProps {
  required?: boolean
  allowApiCalls?: boolean | ((isDirty: boolean) => boolean)
}

const OperatingCarrier: FC<IProps | ReadOnlyProps> = (props) => {
  const { required, allowApiCalls } = props as IProps
  const { readOnly, label = 'Carrier' } = props as ReadOnlyProps

  const {
    watch,
    setValue,
    control,
    formState: { isDirty }
  } = useFormContext()
  const orgId = useStore((store) => store.user.org?.organization_id || '')

  const [usDotDebounce] = useDebounceValue(
    watch(FORM_IDS.POWER_UNIT.USDOT),
    DEBOUNCE_TIME
  )
  const [carrierDebounced] = useDebounceValue(
    watch(FORM_IDS.POWER_UNIT.CARRIER),
    DEBOUNCE_TIME
  )

  const isFetchAllowed = useMemo(() => {
    if (typeof allowApiCalls === 'boolean') return allowApiCalls
    if (typeof allowApiCalls === 'function') return allowApiCalls(isDirty)

    return !readOnly
  }, [allowApiCalls, isDirty, readOnly])

  const [getCarrier, { isFetching: carrierLoading }] = useLazyGetCarrierQuery()
  const { data: carriersResponse, isFetching: carriersLoading } =
    useGetCarriersQuery(
      {
        org_id: orgId,
        partial_name: carrierDebounced || ''
      },
      { skip: !isFetchAllowed }
    )

  const carriersList = carriersResponse?.data?.carriers || []
  const operatingCarrier = watch(FORM_IDS.POWER_UNIT.CARRIER)

  const fetchAndUpdateCarrier = async () => {
    if (!usDotDebounce) return

    // When user select carrier from the list it will automatically
    // replace the usdot number with the selected carrier's usdot number,
    // so we don't need to fetch the carrier details again
    const selectedCarrier = carriersList?.find?.(
      (carrier) =>
        carrier.name === carrierDebounced &&
        carrier.us_dot_number === usDotDebounce
    )

    if (selectedCarrier?.us_dot_number === usDotDebounce) return

    await getCarrier({
      org_id: orgId,
      us_dot: usDotDebounce
    }).then((response) => {
      const carrier = response?.data?.data?.carrier?.name || ''

      setValue(FORM_IDS.POWER_UNIT.CARRIER, carrier, {
        shouldValidate: !!carrier
      })
    })
  }

  const isOptionSelected = useCallback(
    (option: Carrier, value: string | undefined) => {
      const isNameMatch = option.name === value
      const isUsDotMatch = option.us_dot_number === usDotDebounce

      return usDotDebounce ? isNameMatch && isUsDotMatch : isNameMatch
    },
    [usDotDebounce]
  )

  const customOptionLabel = useCallback(
    (option: Carrier) => `${option.name} (${option.us_dot_number})`,
    []
  )

  const onSelectFromList = useCallback(
    (value: Carrier, onChange: ControllerRenderProps['onChange']) => {
      onChange(value?.name || '')

      setValue(FORM_IDS.POWER_UNIT.USDOT, value?.us_dot_number || '')
      setValue(FORM_IDS.CARGO_ASSET.CARRIER, value?.name || '')
    },
    [setValue]
  )

  useEffect(() => {
    if (isFetchAllowed) {
      fetchAndUpdateCarrier()
    }
  }, [usDotDebounce])

  if (carrierLoading) {
    return <ReadOnlyFormValue required title={label} loading />
  }

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={operatingCarrier} />
  }

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.CARRIER}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          required={required}
          label={label}
          name={field.name}
          inputValue={field.value}
          options={carriersList || []}
          loading={carriersLoading}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          onInputChange={(e, newValue) => field.onChange(newValue)}
          getOptionLabel={(option: Carrier) => option?.name || ''}
          customOptionLabel={customOptionLabel}
          isOptionEqualToValue={(option: Carrier) =>
            isOptionSelected(option, field.value)
          }
          onChange={(value: Carrier) => onSelectFromList(value, field.onChange)}
          inputProps={{
            uppercase: true
          }}
        />
      )}
    />
  )
}

export default OperatingCarrier
