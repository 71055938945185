const scrollIntoView = (
  element: HTMLElement | undefined,
  scrollBehaviour: ScrollBehavior = 'smooth',
  position: ScrollLogicalPosition = 'center'
) => {
  if (element) {
    element.scrollIntoView({ behavior: scrollBehaviour, block: position })
  }
}

export default scrollIntoView
