import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  cargoAssetTypesFriendlyNames,
  loadTypesFriendlyNames
} from '@/utils/mappers'
import {
  appointmentTypeToBoolean,
  arrayToString,
  millisecondsToDuration,
  sealMatchesToBoolean,
  userFriendlyBoolean,
  userFriendlyState
} from '@/utils/table'
import friendlyName from '@/utils/table/converters/friendlyName'
import {
  GateTransaction,
  OrderByDomainEventColumn
} from '@/__generated__/graphql'

dayjs.extend(duration)

const driversColumns = (): ITableColumn[] => [
  {
    id: 'eventTime',
    title: 'Check-In Time',
    type: TableColumnType.Date,
    sortable: true,
    sortId: OrderByDomainEventColumn.EventTime
  },
  {
    id: 'metadata.appointmentType',
    title: 'Visitor',
    type: TableColumnType.Text,
    converter: appointmentTypeToBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.AppointmentType
  },
  {
    id: 'metadata.cargoAssetOwnerId',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetOwnerId
  },
  {
    id: 'metadata.chassisId',
    title: 'Chassis ID',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.ChassisId
  },
  {
    id: 'metadata.cargoAssetLicensePlateNumber',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetLicensePlateNumber
  },
  {
    id: 'metadata.cargoAssetLicensePlateState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetLicensePlateState
  },
  {
    id: 'metadata.cargoAssetCarrierName',
    title: 'Asset Carrier',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetCarrierName
  },
  {
    id: 'metadata.powerUnitCarrierName',
    title: 'Carrier',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitCarrierName
  },
  {
    id: 'metadata.cargoAssetAssetType',
    title: 'Asset Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: cargoAssetTypesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetAssetType
  },
  {
    id: 'metadata.loadStatus',
    title: 'Arriving Load Status',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: loadTypesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.LoadStatus
  },
  {
    id: 'metadata.shipmentNumber',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.ShipmentNumber
  },
  {
    id: 'metadata.accountName',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true,
    sortId: OrderByDomainEventColumn.AccountName
  },
  {
    id: 'metadata.sealNumbers',
    title: 'Seal #',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true,
    sortId: OrderByDomainEventColumn.SealNumbers
  },
  {
    id: 'metadata.sealMatchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: sealMatchesToBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.SealMatchPw
  },
  {
    id: 'dwell',
    title: 'Site Dwell',
    type: TableColumnType.Text,
    converter: (row: GateTransaction) =>
      millisecondsToDuration({ dwell: dayjs().diff(row.eventTime) }, 'dwell')
  },
  {
    id: 'metadata.mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.Mismatch
  }
]

export default driversColumns
