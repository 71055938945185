import { IDateRangeFilterValue } from '@/types/interfaces/table'
import { DateRangeFilter } from '@/__generated__/graphql'
import dayjs from 'dayjs'
import { OLDEST_AVAILABLE_DATE } from '@/constants'
import { TimeUnit } from '@/types/enums/ui'

const rangeToDateRangeFilter = (
  dateRange: IDateRangeFilterValue
): DateRangeFilter => {
  const { startDate, endDate } = dateRange

  return {
    startDate: startDate?.isValid()
      ? startDate?.startOf(TimeUnit.Day)?.toISOString()
      : dayjs(OLDEST_AVAILABLE_DATE).toISOString(),

    endDate: endDate?.isValid()
      ? endDate?.endOf(TimeUnit.Day)?.toISOString()
      : dayjs().endOf(TimeUnit.Day).toISOString()
  }
}

export default rangeToDateRangeFilter
