import dayjs from 'dayjs'
import { FC, useMemo } from 'react'

import { Chip } from '@/components/atoms'

import styles from './EventDwellChip.module.scss'
import { pluralize } from '@/utils/helpers'
import { TimeUnit } from '@/types/enums/ui'

interface IProps {
  createdAt: Date
}

const EventDwellChip: FC<IProps> = (props) => {
  const { createdAt } = props

  const { message, warning } = useMemo(() => {
    const dwellTime = dayjs().diff(createdAt)
    const dwellMilliseconds = dayjs.duration(dwellTime, TimeUnit.Millisecond)

    const days = dwellMilliseconds.days()
    const hours = dwellMilliseconds.hours()
    const mins = dwellMilliseconds.minutes()

    if (days) {
      return {
        message: pluralize(days, TimeUnit.Day),
        warning: true
      }
    }

    if (hours) {
      return {
        message: pluralize(hours, TimeUnit.Hour),
        warning: true
      }
    }

    if (mins) {
      return {
        message: `${mins} min`,
        warning: false
      }
    }

    return { message: 'Just now', warning: false }
  }, [createdAt])

  return (
    <Chip
      type={warning ? 'warning' : 'default'}
      label={message}
      className={styles.eventDwell}
    />
  )
}

export default EventDwellChip
