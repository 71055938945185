import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { Checkbox } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { ReadOnlyProps } from '@/types/interfaces/ui'

const SealMatchesPW: FC<ReadOnlyProps> = (props) => {
  const { readOnly } = props

  const { setValue, watch } = useFormContext()

  const sealMatches = watch(FORM_IDS.CARGO_ASSET.SEAL_MATCHES_PW)

  const onChange = () =>
    setValue(FORM_IDS.CARGO_ASSET.SEAL_MATCHES_PW, !sealMatches)

  return (
    <Checkbox
      disabled={readOnly}
      value={sealMatches}
      label="The seal matches the paperwork"
      onChange={onChange}
    />
  )
}

export default SealMatchesPW
