import { CreateGateTransactionMetadata } from '@/features/gate/api/types'
import { SealMatchesPW } from '@/features/gate/enums'
import {
  GateTransactionSchemaType,
  getSealMatchesPWValue,
  isAssetEmpty,
  isCargoAssetAllowed,
  isCargoAssetMinimized,
  isChassisIdVisible,
  isNotVisitor
} from '@/features/gate/utils'
import {
  CargoAssetTypes,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'
import { prepareSealNumbers, valueOrEmpty } from '@/utils/helpers'
import { emissionTypeByFuelMapper } from '@/utils/mappers'

interface Props {
  formData: GateTransactionSchemaType
  initialMetadata: Partial<CreateGateTransactionMetadata>
  hasPowerUnitAndDriver: boolean
  hasCargoAsset: boolean
}

const prepareGateTransactionMetadata = (
  props: Props
): CreateGateTransactionMetadata => {
  const { formData, initialMetadata, hasPowerUnitAndDriver, hasCargoAsset } =
    props
  const {
    appointment_type,

    power_unit_type = '',
    power_unit_owner_id = '',
    power_unit_license_plate_number = '',
    power_unit_license_plate_state = '',
    power_unit_carrier_usdot = '',
    power_unit_weight_class = '',
    power_unit_fuel_type = '',
    power_unit_carrier_name = '',
    power_unit_vin = '',
    account_name = '',

    driver_first_name = '',
    driver_last_name = '',
    driver_license_number = '',
    driver_phone_number = '',
    driver_license_state = '',

    cargo_asset_owner_id = '',
    cargo_asset_license_plate_number = '',
    cargo_asset_license_plate_state = '',
    cargo_asset_carrier_name = '',
    cargo_asset_asset_type = '',
    load_status = '',
    shipment_number = '',
    seal_numbers = [],
    chassis_id = '',
    seal_matchPW,

    inspection_completed
  } = formData

  const cargoAssetAllowed = isCargoAssetAllowed(
    appointment_type,
    power_unit_type as PowerUnitTypes
  )

  const metadata: CreateGateTransactionMetadata = {
    ...initialMetadata,
    appointment_type,

    power_unit_type: '',
    power_unit_owner_id: '',
    power_unit_license_plate_number: '',
    power_unit_license_plate_state: '',

    power_unit_carrier_usdot: '',
    power_unit_carrier_name: '',
    power_unit_carrier_MCNum: '',

    power_unit_vin: '',
    power_unit_weight_class: '',
    power_unit_fuel_type: '',
    power_unit_emission_type: '',
    account_name: [],

    driver_first_name: '',
    driver_last_name: '',
    driver_license_number: '',
    driver_phone_number: '',
    driver_license_state: '',

    cargo_asset_owner_id: '',
    cargo_asset_license_plate_number: '',
    cargo_asset_license_plate_state: '',
    cargo_asset_carrier_name: '',
    cargo_asset_asset_type: '',
    load_status: '',
    shipment_number: '',
    seal_numbers: [],
    seal_matchPW: SealMatchesPW.NoSeal,
    chassis_id: '',

    mismatch: false,
    inspection_completed: null
  } as CreateGateTransactionMetadata

  if (hasPowerUnitAndDriver) {
    metadata.power_unit_type = power_unit_type
    metadata.power_unit_owner_id = power_unit_owner_id
    metadata.power_unit_license_plate_number = power_unit_license_plate_number
    metadata.power_unit_license_plate_state = power_unit_license_plate_state

    metadata.power_unit_carrier_usdot = power_unit_carrier_usdot
    metadata.power_unit_carrier_name = power_unit_carrier_name
    metadata.power_unit_carrier_MCNum = ''

    metadata.power_unit_vin = power_unit_vin
    metadata.power_unit_weight_class = power_unit_weight_class
    metadata.power_unit_fuel_type = power_unit_fuel_type
    metadata.power_unit_emission_type =
      emissionTypeByFuelMapper[power_unit_fuel_type as FuelTypes]

    metadata.driver_first_name = driver_first_name
    metadata.driver_last_name = driver_last_name
    metadata.driver_license_number = driver_license_number
    metadata.driver_phone_number = driver_phone_number || ''
    metadata.driver_license_state = driver_license_state

    if (isNotVisitor(appointment_type)) {
      metadata.account_name = account_name ? (account_name as string[]) : []
    }
  }

  if (cargoAssetAllowed && hasCargoAsset) {
    const isMinimized = isCargoAssetMinimized(power_unit_type as PowerUnitTypes)

    metadata.load_status = load_status || ''
    metadata.inspection_completed = inspection_completed || null

    metadata.cargo_asset_license_plate_number = valueOrEmpty(
      isMinimized
        ? initialMetadata.cargo_asset_license_plate_number
        : cargo_asset_license_plate_number
    )
    metadata.cargo_asset_license_plate_state = valueOrEmpty(
      isMinimized
        ? initialMetadata.cargo_asset_license_plate_state
        : cargo_asset_license_plate_state
    )
    metadata.cargo_asset_owner_id = valueOrEmpty(
      isMinimized ? initialMetadata.cargo_asset_owner_id : cargo_asset_owner_id
    )
    metadata.cargo_asset_carrier_name = valueOrEmpty(
      isMinimized
        ? initialMetadata.cargo_asset_carrier_name
        : cargo_asset_carrier_name
    )
    metadata.cargo_asset_asset_type = valueOrEmpty(
      isMinimized
        ? initialMetadata.cargo_asset_asset_type
        : cargo_asset_asset_type
    ) as CargoAssetTypes

    if (!isAssetEmpty(load_status as LoadTypes)) {
      metadata.shipment_number = shipment_number || ''
      metadata.seal_numbers = prepareSealNumbers(seal_numbers)
      metadata.seal_matchPW = getSealMatchesPWValue(seal_matchPW, seal_numbers)
    }

    if (
      isChassisIdVisible(
        power_unit_type as PowerUnitTypes,
        cargo_asset_asset_type as CargoAssetTypes
      )
    ) {
      metadata.chassis_id = chassis_id || ''
    }
  }

  return metadata
}

export default prepareGateTransactionMetadata
