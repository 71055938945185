import { GateQueueEvent } from '@/features/gate/types'
import { GateTransaction } from '@/__generated__/graphql'

const isPowerUnitPresent = (
  item: GateQueueEvent | GateTransaction | undefined
): boolean => {
  if (!item) return false

  const event = item as GateQueueEvent
  const transaction = item as GateTransaction

  // If the gate queue event was passed (used for check-in/out on initial load)
  if (event.metadata.AssetChainEvent) return !!item

  // LPN is required for power unit section so if it is present, the section is present
  return !!transaction.metadata.powerUnitLicensePlateNumber
}

export default isPowerUnitPresent
