import { KeyboardEvent } from 'react'

import { KeyCode } from '@/types/enums/ui'

const onEnterPressed = (
  e: KeyboardEvent<HTMLElement>,
  callback: () => void
) => {
  if (e.key === KeyCode.Enter) {
    callback()
  }
}

export default onEnterPressed
