import { get } from 'lodash'
import { isNotVisitor } from '@/features/gate/utils'
import { GateTransaction } from '@/__generated__/graphql'

const appointmentTypeToBoolean = (row: GateTransaction, id: string) => {
  const appointmentType = get(row, id)

  return isNotVisitor(appointmentType) ? 'No' : 'Yes'
}

export default appointmentTypeToBoolean
