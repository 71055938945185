import {
  baseApi,
  getAllCacheEntriesByKey,
  invalidateTagsOnSuccess,
  withParams
} from '@/api'
import * as Sentry from '@sentry/react'

import { HttpMethod } from '@/api/enums'
import {
  ICancelInviteRequest,
  IEnableDisableUserRequest,
  IEnableDisableUserResponse,
  IEnableSsoRequest,
  IEnableSsoResponse,
  IFetchOrganizationConnectionsRequest,
  IFetchOrganizationConnectionsResponse,
  IGetInvitesResponse,
  IGetUsersResponse,
  IInviteUserRequest,
  IOrgRolesResponse,
  ISiteRolesRequest,
  ISiteRolesResponse,
  ISitesResponse,
  IUpdateOrgRolesRequest,
  IUpdateOrgRolesResponse,
  IUpdateSiteRolesRequest,
  IUpdateSiteRolesResponse,
  IUpdateUserProfileRequest,
  IUpdateUserProfileResponse
} from '@/features/user-management/api/types'
import {
  DefaultApiResponse,
  IGetUsersRequest,
  IOrgAndUserIdRequest,
  IOrgIdRequest
} from '@/types/interfaces/api'
import endpoints from './endpoints'

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchUsers: builder.query<IGetUsersResponse, IGetUsersRequest>({
      providesTags: ['User'],
      query: ({ orgId, ...query }) => ({
        url: withParams(endpoints.GET_USERS, { orgId }),
        params: query
      })
    }),

    fetchInvites: builder.query<IGetInvitesResponse, IOrgIdRequest>({
      providesTags: ['Invite'],
      query: ({ orgId }) => {
        return withParams(endpoints.GET_INVITES, { orgId })
      }
    }),

    enableDisableUser: builder.mutation<
      IEnableDisableUserResponse,
      IEnableDisableUserRequest
    >({
      query: (args) => ({
        url: withParams(
          args.disable ? endpoints.DISABLE_USER : endpoints.ENABLE_USER,
          { orgId: args.orgId, userId: args.userId }
        ),
        method: HttpMethod.POST
      }),

      async onQueryStarted(
        { orgId, userId, disable },
        { dispatch, queryFulfilled, getState }
      ) {
        try {
          await queryFulfilled

          const queries = getAllCacheEntriesByKey(getState, 'fetchUsers')

          queries.forEach((query) => {
            if (!query?.data) return

            const args = query?.originalArgs as IGetUsersRequest

            dispatch(
              userApi.util.updateQueryData('fetchUsers', args, (draft) => {
                draft.data.members = draft.data.members.map((member) =>
                  member.id === userId
                    ? {
                        ...member,
                        disabled: disable
                      }
                    : member
                )
              })
            )
          })
        } catch (e) {
          Sentry.captureException(e)
        }
      }
    }),

    inviteUser: builder.mutation<DefaultApiResponse, IInviteUserRequest>({
      invalidatesTags: invalidateTagsOnSuccess(['Invite']),
      query: ({ orgId, ...body }) => ({
        url: withParams(endpoints.SEND_INVITE, { orgId }),
        method: HttpMethod.POST,
        body
      })
    }),

    cancelInvite: builder.mutation<DefaultApiResponse, ICancelInviteRequest>({
      invalidatesTags: invalidateTagsOnSuccess(['Invite']),
      query: (args) => ({
        url: withParams(endpoints.DELETE_INVITE, {
          orgId: args.orgId,
          inviteId: args.inviteId
        }),
        method: HttpMethod.DELETE
      })
    }),

    deleteUser: builder.mutation<DefaultApiResponse, IOrgAndUserIdRequest>({
      invalidatesTags: invalidateTagsOnSuccess(['User']),
      query: (args) => ({
        url: withParams(endpoints.DELETE_USER, {
          orgId: args.orgId,
          userId: args.userId
        }),
        method: HttpMethod.DELETE
      })
    }),

    fetchUserOrgRoles: builder.query<IOrgRolesResponse, IOrgAndUserIdRequest>({
      providesTags: (res, error, req) => [{ type: 'Role', id: req.userId }],
      keepUnusedDataFor: 0,
      query: ({ userId, orgId }) => {
        return withParams(endpoints.GET_USER_ORG_ROLES, { userId, orgId })
      }
    }),

    fetchUserSites: builder.query<ISitesResponse, IOrgAndUserIdRequest>({
      providesTags: (res, err, req) => [
        {
          type: 'Site',
          id: req.userId
        }
      ],
      keepUnusedDataFor: 0,
      query: ({ userId, orgId }) => {
        return withParams(endpoints.GET_USER_SITES, { userId, orgId })
      }
    }),

    fetchUserSiteRoles: builder.query<ISiteRolesResponse, ISiteRolesRequest>({
      providesTags: (res, err, req) => [
        {
          type: 'SiteRole',
          id: `${req.userId}-${req.siteId}`
        }
      ],
      keepUnusedDataFor: 0,
      query: ({ userId, orgId, siteId }) => {
        return withParams(endpoints.GET_USER_SITE_ROLES, {
          userId,
          orgId,
          siteId
        })
      }
    }),

    updateUserDetails: builder.mutation<
      IUpdateUserProfileResponse,
      IOrgAndUserIdRequest & Partial<IUpdateUserProfileRequest>
    >({
      invalidatesTags: invalidateTagsOnSuccess(['User']),
      query: ({ userId, orgId, ...body }) => ({
        url: withParams(endpoints.UPDATE_USER_PROFILE, {
          orgId,
          userId
        }),
        method: HttpMethod.PUT,
        body: body
      })
    }),

    updateOrgRoles: builder.mutation<
      IUpdateOrgRolesResponse,
      IOrgAndUserIdRequest & IUpdateOrgRolesRequest
    >({
      query: ({ userId, orgId, ...body }) => ({
        url: withParams(endpoints.UPDATE_USER_ENTERPRISE_ROLES, {
          orgId,
          userId
        }),
        method: HttpMethod.PUT,
        body: body
      })
    }),

    updateSiteRoles: builder.mutation<
      IUpdateSiteRolesResponse,
      IUpdateSiteRolesRequest
    >({
      query: ({ userId, orgId, siteId, ...body }) => ({
        url: withParams(endpoints.UPDATE_USER_SITE_ROLES, {
          orgId,
          userId,
          siteId
        }),
        method: HttpMethod.PUT,
        body: body
      })
    }),

    enableSso: builder.mutation<IEnableSsoResponse, IEnableSsoRequest>({
      query: ({ orgId, ...body }) => ({
        url: withParams(endpoints.ORGANIZATION_AUTHENTICATION, { orgId }),
        method: HttpMethod.POST,
        body: body
      })
    }),

    fetchOrganizationConnections: builder.query<IFetchOrganizationConnectionsResponse, IFetchOrganizationConnectionsRequest>({
      query: ({ orgId }) => ({
        url: withParams(endpoints.ORGANIZATION_AUTHENTICATION, { orgId }),
        method: HttpMethod.GET
      })
    })
  })
})

export const {
  useFetchUsersQuery,
  useInviteUserMutation,
  useFetchInvitesQuery,
  useEnableDisableUserMutation,
  useCancelInviteMutation,
  useDeleteUserMutation,
  useLazyFetchUserOrgRolesQuery,
  useLazyFetchUserSitesQuery,
  useLazyFetchUserSiteRolesQuery,
  useUpdateUserDetailsMutation,
  useUpdateOrgRolesMutation,
  useUpdateSiteRolesMutation,
  useEnableSsoMutation,
  useLazyFetchOrganizationConnectionsQuery
} = userApi
