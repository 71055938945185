import { Popover } from '@mui/material'
import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Col, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { INavigationSubItem } from '@/types/interfaces/ui'

import styles from './CollapsedNavigationPopover.module.scss'

type IExtendedSubItem = Omit<INavigationSubItem, 'link'> & {
  action?: () => void
  link?: string
}

type PopoverItem = Omit<IExtendedSubItem, 'visible'>

interface IProps {
  name: string
  anchorEl: HTMLElement | null
  onClose: () => void
  title: string
  Icon: ReactNode
  items: PopoverItem[]
  className?: string
}

const CollapsedNavigationPopover: FC<IProps> = (props) => {
  const { name, anchorEl, onClose, title, items, Icon, className } = props

  const location = useLocation()

  const open = Boolean(anchorEl)

  const renderItem = (item: PopoverItem) => {
    const isCurrentPage = location.pathname === item.link

    const subItemClassName = clsx(
      styles.subItemLink,
      isCurrentPage && styles.active
    )

    const Title = (
      <Text
        color={Color.gray300}
        type={TextTypes.TEXT_SM}
        weight={isCurrentPage ? FontWeight.BOLD : FontWeight.REGULAR}
      >
        {item.title}
      </Text>
    )

    if (item.link) {
      return (
        <Link
          to={item.link}
          key={item.id}
          onClick={onClose}
          className={subItemClassName}
        >
          {Title}
        </Link>
      )
    }

    return (
      <Row onClick={item.action} key={item.id} className={subItemClassName}>
        {Title}
      </Row>
    )
  }

  return (
    <Popover
      id={name}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      classes={{ paper: clsx(styles.navigationItemPopover, className) }}
    >
      <Row items="center" gap={8} className={styles.popoverHeader}>
        {Icon}

        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.REGULAR}
          color={Color.gray300}
        >
          {title}
        </Text>
      </Row>

      <Col items="stretch" className="tw-py-8">
        {items.map(renderItem)}
      </Col>
    </Popover>
  )
}

export default CollapsedNavigationPopover
