import { Collapse } from '@mui/material'
import clsx from 'clsx'
import { FC, PropsWithChildren, useState } from 'react'

import { ToggleDetailsButton } from '@/components/molecules'

import styles from './ToggleDetails.module.scss'

const ToggleDetails: FC<PropsWithChildren> = (props) => {
  const { children } = props

  const [open, setOpen] = useState<boolean>(false)
  const [animationInProgress, setAnimationInProgress] = useState<boolean>(false)

  const toggleDetails = () => {
    setOpen(!open)

    if (open) {
      setAnimationInProgress(true)
    }
  }

  return (
    <>
      <Collapse
        in={open}
        unmountOnExit
        onExited={() => setAnimationInProgress(false)}
        className={clsx(animationInProgress && styles.animationInProgress)}
      >
        {children}
      </Collapse>

      <ToggleDetailsButton
        showDetails={open}
        className={styles.toggleButton}
        onClick={toggleDetails}
      />
    </>
  )
}

export default ToggleDetails
