import { FC, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Row, Text } from '@/components/atoms'
import { Tabs } from '@/components/molecules'
import { FormSectionType } from '@/features/forms/enums'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { ITab } from '@/types/interfaces/ui'
import { scrollIntoView } from '@/utils/helpers'

import styles from './FormAnchorTabs.module.scss'
import { FORM_IDS, FORM_SECTION_ANCHORS } from '@/features/forms/constants'
import { formSectionNameMapper } from '@/features/forms/utils'
import { isCargoAssetAllowed } from '@/features/gate/utils'

interface Props {
  onAdd?: (section: FormSectionType) => void
  hasPowerUnitAndDriver: boolean
  hasCargoAsset: boolean
}

const FormAnchorTabs: FC<Props> = (props) => {
  const { onAdd, hasPowerUnitAndDriver, hasCargoAsset } = props

  const [active, setActive] = useState<string | undefined>(
    FORM_SECTION_ANCHORS.GENERAL.ID
  )

  const { watch } = useFormContext() || {}

  const allowCargoAsset = isCargoAssetAllowed(
    watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE),
    watch(FORM_IDS.POWER_UNIT.TYPE)
  )

  const { memoTabs, Actions } = useMemo(() => {
    const tabs: ITab<string>[] = [
      { id: FORM_SECTION_ANCHORS.GENERAL.ID, title: 'General' }
    ]

    const actions: FormSectionType[] = []

    if (hasPowerUnitAndDriver) {
      tabs.push(
        {
          id: FORM_SECTION_ANCHORS.POWER_UNIT.ID,
          title: FORM_SECTION_ANCHORS.POWER_UNIT.TITLE
        },
        {
          id: FORM_SECTION_ANCHORS.DRIVER.ID,
          title: FORM_SECTION_ANCHORS.DRIVER.TITLE
        }
      )
    } else if (onAdd) {
      actions.push(FormSectionType.PowerUnitAndDriver)
    }

    if (hasCargoAsset) {
      tabs.push({
        id: FORM_SECTION_ANCHORS.CARGO_ASSET.ID,
        title: FORM_SECTION_ANCHORS.CARGO_ASSET.TITLE
      })
    } else if (onAdd && allowCargoAsset) {
      actions.push(FormSectionType.CargoAsset)
    }

    return {
      memoTabs: tabs,
      Actions: !!actions.length && (
        <Row items="center">
          {actions.map((action) => (
            <Text
              key={action}
              type={TextTypes.TEXT_SM}
              weight={FontWeight.SEMIBOLD}
              color={Color.green500}
              onClick={() => onAdd?.(action)}
              className={styles.actionButton}
            >
              + {formSectionNameMapper[action]}
            </Text>
          ))}
        </Row>
      )
    }
  }, [hasCargoAsset, hasPowerUnitAndDriver, allowCargoAsset])

  const onTabSelect = (tab: string | undefined) => {
    if (tab) {
      const formSection = document.getElementById(tab)

      if (formSection) {
        scrollIntoView(formSection)
        setActive(tab)
      }
    }
  }

  return (
    <div className={styles.tabsWrapper}>
      <Tabs<string>
        active={active}
        setActive={onTabSelect}
        tabs={memoTabs}
        Actions={Actions}
      />
    </div>
  )
}

export default FormAnchorTabs
