import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  ListItem
} from '@mui/material'
import React, { FC, memo, useMemo } from 'react'

import { Input } from '@/components/atoms'
import { TextInputProps } from '@/types/interfaces/ui'

import styles from './Autocomplete.module.scss'

interface IProps
  extends Omit<
    AutocompleteProps<
      any,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >,
    'renderInput' | 'renderOption'
  > {
  name?: string
  freeSolo?: boolean
  onChange: (value: any) => void
  isOptionEqualToValue: (option: any, value: any) => boolean
  required?: boolean
  helperText?: string
  label?: string
  error?: boolean
  loading?: boolean
  customOptionLabel?: (option: any) => string
  inputProps?: Partial<TextInputProps>
}

const CustomAutocomplete: FC<IProps> = (props) => {
  const {
    name,
    label,
    options,
    onChange,
    value,
    freeSolo = true,
    required,
    helperText,
    error,
    fullWidth = true,
    loading = false,
    isOptionEqualToValue,
    customOptionLabel,
    inputProps = {},
    inputValue,
    ...restProps
  } = props

  const customValue = useMemo(
    () =>
      options.find((option) => isOptionEqualToValue(option, inputValue)) ||
      null,
    [isOptionEqualToValue, inputValue, options]
  )

  return (
    <Autocomplete
      {...restProps}
      forcePopupIcon
      disablePortal
      fullWidth={fullWidth}
      freeSolo={freeSolo}
      options={options}
      loading={loading}
      value={value || customValue}
      inputValue={inputValue}
      ListboxProps={{ className: styles.autocompleteDropdown }}
      renderOption={
        customOptionLabel
          ? (optionProps, option) => (
              <ListItem {...optionProps}>{customOptionLabel(option)}</ListItem>
            )
          : undefined
      }
      renderInput={(params) => (
        <Input
          {...params}
          {...inputProps}
          name={name}
          onChange={params.inputProps.onChange}
          InputProps={{
            ...params.InputProps,
            // Removes default browser autocomplete
            autoComplete: 'off',
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          error={error}
          helperText={helperText}
          required={required}
          label={label}
        />
      )}
      onChange={(e, newValue) => {
        onChange(newValue || undefined)
      }}
    />
  )
}

export default memo(CustomAutocomplete)
