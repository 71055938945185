import { GateQueueEvent } from '@/features/gate/types'
import {
  AppointmentTypes,
  CargoAssetTypes
} from '@/types/enums/transactionDetails'
import { convertStateFromTVE } from '@/utils/helpers'
import { powerUnitTypeByAssetChainType } from '@/utils/mappers'
import { getCargoAssetTypeFromAssetChain } from '@/features/gate/utils'

const getFormValuesFromQueueEvent = (event: GateQueueEvent | undefined) => {
  const commonValues = {
    date: new Date(),
    appointment_type: AppointmentTypes.Scheduled
  }

  if (!event) return commonValues

  const { metadata } = event

  const {
    AssetChainEvent: { asset_chain },

    power_unit_owner_id,
    power_unit_license_plate_number,
    power_unit_license_plate_state,
    power_unit_carrier_usdot,

    cargo_asset_owner_id,
    cargo_asset_license_plate_number,
    cargo_asset_license_plate_state,
    chassis_id,
    shipment_number,
    seal_numbers
  } = metadata

  const { type } = asset_chain

  return {
    ...commonValues,

    power_unit_type: powerUnitTypeByAssetChainType[type],
    power_unit_owner_id: power_unit_owner_id?.[0] || '',
    power_unit_license_plate_number: power_unit_license_plate_number?.[0] || '',
    power_unit_license_plate_state: convertStateFromTVE(
      power_unit_license_plate_state?.[0]
    ),
    power_unit_carrier_usdot: power_unit_carrier_usdot?.[0] || '',
    account_name: [],

    cargo_asset_owner_id: cargo_asset_owner_id?.[0] || '',
    cargo_asset_license_plate_number:
      cargo_asset_license_plate_number?.[0] || undefined,
    cargo_asset_license_plate_state: convertStateFromTVE(
      cargo_asset_license_plate_state?.[0]
    ),
    cargo_asset_asset_type: getCargoAssetTypeFromAssetChain(asset_chain),
    chassis_id: chassis_id?.[0] || '',
    shipment_number: shipment_number || '',
    seal_numbers: seal_numbers || []
  }
}

export default getFormValuesFromQueueEvent
