import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { ReadOnlyFormValue, Select } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { CargoAssetTypes } from '@/types/enums/transactionDetails'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import {
  cargoAssetTypeSelectOptions,
  cargoAssetTypesFriendlyNames
} from '@/utils/mappers'

const Type: FC<ReadOnlyProps> = (props) => {
  const { readOnly, label = 'Asset Type' } = props

  const { control, watch } = useFormContext()

  const assetType = watch(FORM_IDS.CARGO_ASSET.TYPE) as CargoAssetTypes

  if (readOnly) {
    return (
      <ReadOnlyFormValue
        title={label}
        value={!!assetType && cargoAssetTypesFriendlyNames[assetType]}
      />
    )
  }

  return (
    <Controller
      name={FORM_IDS.CARGO_ASSET.TYPE}
      control={control}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          required
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          options={cargoAssetTypeSelectOptions}
        />
      )}
    />
  )
}

export default Type
