import AddIcon from '@mui/icons-material/Add'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import React, { FC, UIEvent, useEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { Button, Col, Row, Text } from '@/components/atoms'
import { BREAKPOINTS } from '@/constants'
import { QueueCard } from '@/features/gate'
import { LaneDirection } from '@/features/gate/enums'
import { GateQueueEvent, Lane } from '@/features/gate/types'
import { getLaneDirectionName } from '@/features/gate/utils'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './GateQueue.module.scss'
import { BaseEmptyState } from '@/components/organisms'
import GateTransactions from '@/assets/icons/gate_transactions.svg?react'

interface IProps {
  type: LaneDirection
  lane: Lane
  isActionAllowed: boolean
  items: GateQueueEvent[]
  onSwapLane: (item: GateQueueEvent, direction: LaneDirection) => void
  totalCount: number
  openModal: (
    item: GateQueueEvent | undefined,
    lane: Lane,
    deleteItem?: boolean
  ) => void
}

const GateQueue: FC<IProps> = (props) => {
  const {
    type,
    lane,
    items,
    totalCount,
    isActionAllowed,
    onSwapLane,
    openModal
  } = props

  const [selectedItem, setSelectedItem] = useState<GateQueueEvent | undefined>()
  const [showBorder, setShowBorder] = useState<boolean>(false)

  const { width } = useWindowSize()

  const emptyQueueDescription = `Power units ready to be checked-${
    type === LaneDirection.Arriving ? 'in' : 'out'
  } will appear here.`

  const tabsMode = width <= BREAKPOINTS.SM

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.target as HTMLElement

    const scrolled = scrollTop > 0

    if (scrolled !== showBorder) {
      setShowBorder(scrolled)
    }
  }

  useEffect(() => {
    if (selectedItem && items.length) {
      const item = items.find((i) => i.id === selectedItem.id)

      if (item?.id && !isEqual(selectedItem, item)) {
        setSelectedItem(item)
      }
    }
  }, [selectedItem, items])

  return (
    <div
      className={clsx(styles.queueWrapper, tabsMode && styles.small)}
      onScroll={handleScroll}
    >
      {!tabsMode && (
        <Row
          items="center"
          gap={20}
          justify="between"
          className={clsx(styles.queueHeader, showBorder && styles.border)}
        >
          <Row items="center" gap={10}>
            <Text
              type={TextTypes.TEXT_MD}
              color={Color.gray700}
              weight={FontWeight.SEMIBOLD}
            >
              {getLaneDirectionName(type)}
            </Text>

            {!!totalCount && (
              <div className={styles.queueLength}>
                <Text
                  type={TextTypes.TEXT_MD}
                  weight={FontWeight.SEMIBOLD}
                  color={Color.gray700}
                >
                  {totalCount}
                </Text>
              </div>
            )}
          </Row>

          {isActionAllowed && (
            <Button type="outlined" onClick={() => openModal(undefined, lane)}>
              <AddIcon />
            </Button>
          )}
        </Row>
      )}

      {items.length ? (
        <Col gap={16} items="center" className={styles.queueItems}>
          {items.map((item) => (
            <QueueCard
              key={item.id}
              type={type}
              item={item}
              onSwapLane={onSwapLane}
              isActionAllowed={isActionAllowed}
              onCheckInOut={(i) => openModal(i, lane)}
              onDelete={() => openModal(item as GateQueueEvent, lane, true)}
            />
          ))}
        </Col>
      ) : (
        <BaseEmptyState
          primaryText="Nothing in the queue"
          descriptionText={emptyQueueDescription}
          Icon={<GateTransactions />}
        />
      )}
    </div>
  )
}

export default GateQueue
