import { TableFilterType } from '@/types/enums/table'
import { IFilterItem } from '@/types/interfaces/table'
import {
  isVisitorSelectOptions,
  classListSelectOptions,
  fuelTypeSelectOptions,
  mismatchSelectOptions
} from '@/utils/mappers'
import {
  dwellToDateRangeFilter,
  listToStringFilter
} from '@/utils/table/filterRequestFormatters'

const driversFilters: IFilterItem[] = [
  {
    id: 'appointmentType',
    name: 'Visitor',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    requestFormatter: listToStringFilter,
    options: isVisitorSelectOptions
  },
  {
    id: 'powerUnitWeightClass',
    name: 'Class',
    type: TableFilterType.List,
    placeholder: 'Search Class',
    requestFormatter: listToStringFilter,
    options: classListSelectOptions
  },
  {
    id: 'dwell',
    name: 'Dwell',
    type: TableFilterType.Dwell,
    requestFormatter: dwellToDateRangeFilter
  },
  {
    id: 'powerUnitFuelType',
    name: 'Fuel Type',
    type: TableFilterType.List,
    placeholder: 'Search Fuel Type',
    requestFormatter: listToStringFilter,
    options: fuelTypeSelectOptions
  },
  {
    id: 'mismatch',
    name: 'Mismatches',
    type: TableFilterType.List,
    placeholder: 'Search Value',
    requestFormatter: listToStringFilter,
    options: mismatchSelectOptions
  }
]

export default driversFilters
