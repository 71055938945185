import { GateQueueEvent } from '@/features/gate/types'
import { containsKeys } from '@/utils/helpers'
import { powerUnitTypeByAssetChainType } from '@/utils/mappers'
import { PowerUnitTypes } from '@/types/enums/transactionDetails'
import { GateTransaction } from '@/__generated__/graphql'

const isCargoAssetPresent = (
  item: GateQueueEvent | GateTransaction | undefined
): boolean => {
  if (!item) return false

  const event = item as GateQueueEvent
  const transaction = item as GateTransaction

  // If the gate queue event was passed (used for check-in/out on initial load)
  if (event.metadata.AssetChainEvent) return true

  // Load status is required for cargo asset section so if it is present, the section is present
  return !!transaction.metadata.loadStatus
}

export default isCargoAssetPresent
