import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Input, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { NameType } from '@/features/forms/enums'
import { ReadOnlyProps } from '@/types/interfaces/ui'

interface IProps {
  type: NameType
}

const namesMapper = {
  [NameType.First]: 'First Name',
  [NameType.Last]: 'Last Name',
  [NameType.Full]: 'Driver Name'
}

const idsMapper = {
  [NameType.First]: FORM_IDS.DRIVER_DETAILS.FIRST_NAME,
  [NameType.Last]: FORM_IDS.DRIVER_DETAILS.LAST_NAME,
  [NameType.Full]: FORM_IDS.DRIVER_DETAILS.FULL_NAME
}

const Name: FC<IProps & ReadOnlyProps> = (props) => {
  const { type, readOnly, label = namesMapper[type] } = props
  const { control, watch } = useFormContext()

  const fieldId = idsMapper[type]
  const value = watch(fieldId)

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={value} />
  }

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          required
          fullWidth
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default Name
