import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { FC } from 'react'

import { Col } from '@/components/atoms'
import { ImageWithPreview } from '@/components/molecules'

import styles from './TruckImagesWithLoader.module.scss'

interface Props {
  showImage?: boolean
  image?: string | undefined
  title: string
  className?: string
}

const TruckImageWithLoader: FC<Props> = (props) => {
  const { image, showImage = !!image, title, className } = props

  if (!showImage) return null

  return image ? (
    <ImageWithPreview
      imageUrl={image}
      title={title}
      className={clsx(styles.truckImg, className)}
    />
  ) : (
    <Col
      items="center"
      justify="center"
      className={clsx(styles.truckImg, className)}
    >
      <CircularProgress color="secondary" />
    </Col>
  )
}

export default TruckImageWithLoader
