import { SitePortalPermissions } from '@/features/auth/types'

export const DEFAULT_SITE_PERMISSIONS: {
  [key in keyof SitePortalPermissions]: boolean
} = {
  gate_queue: false,
  gate_transaction: false,
  onsite_driver: false,
  onsite_cargo_asset: false,
  isr: false
}
