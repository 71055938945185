import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MenuIcon from '@mui/icons-material/Menu'
import { IconButton, Popover } from '@mui/material'
import clsx from 'clsx'
import { FC, KeyboardEvent, PropsWithChildren, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { Row, Text } from '@/components/atoms'
import { useMobileNavigationContext } from '@/components/contexts'
import { useLastUrlPart } from '@/hooks'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { ITab } from '@/types/interfaces/ui'
import { onEnterPressed } from '@/utils/helpers'

import styles from './WithHeaderTemplate.module.scss'

interface IProps {
  title: string
  className?: string
  showBottomBorder?: boolean
  Action?: JSX.Element
  dropdownTabs?: ITab[]
}

const WithHeaderTemplate: FC<IProps & PropsWithChildren> = (props) => {
  const {
    children,
    title,
    className,
    showBottomBorder = true,
    Action,
    dropdownTabs = []
  } = props

  const MENU_ICON_LENGTH = 16

  const [tabPopoverOpen, setTabPopoverOpen] = useState<boolean>(false)

  const headerRowRef = useRef<HTMLDivElement | null>(null)

  const { toggleMobileNavigation } = useMobileNavigationContext()

  const lastUrlPart = useLastUrlPart()

  const hasDropdownTabs = dropdownTabs.length > 0

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    onEnterPressed(e, toggleMobileNavigation)
  }

  function getTitle() {
    if (hasDropdownTabs) {
      return dropdownTabs.find((tab) => tab.id === lastUrlPart)?.title
    }

    return title
  }

  function getTabBackgroundColor(tab: ITab) {
    return lastUrlPart === tab.id ? '#D6D6D6' : ''
  }

  const toggleDropdownTabs = () => setTabPopoverOpen(!tabPopoverOpen)

  const popover = (
    <Popover
      disablePortal
      id="header-template-tab-popover"
      open={tabPopoverOpen}
      anchorEl={headerRowRef.current}
      onClose={() => setTabPopoverOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 4,
          marginBottom: 4
        }}
      >
        {dropdownTabs?.map((item) => (
          <Link to={`./${item.id}`} key={item.id}>
            <div
              id={item.id}
              key={item.id}
              tabIndex={0}
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8,
                background: getTabBackgroundColor(item)
              }}
              onKeyDown={handleKeyDown}
            >
              <Text
                type={TextTypes.TEXT_MD}
                weight={FontWeight.REGULAR}
                color={Color.gray700}
              >
                {item.title}
              </Text>
            </div>
          </Link>
        ))}
      </div>
    </Popover>
  )

  return (
    <div className={styles.content}>
      <header
        className={clsx(styles.header, {
          [styles.withBorder]: showBottomBorder
        })}
      >
        <Row items="center" gap={8} ref={headerRowRef}>
          <div
            tabIndex={0}
            onKeyDown={handleKeyDown}
            className={styles.burgerIcon}
            onClick={() => toggleMobileNavigation()}
          >
            <MenuIcon width={MENU_ICON_LENGTH} height={MENU_ICON_LENGTH} />
          </div>

          <Text
            type={TextTypes.TEXT_XL}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray700}
            {...(hasDropdownTabs && {
              onClick: toggleDropdownTabs
            })}
          >
            {getTitle()}
          </Text>
          {dropdownTabs.length > 1 && (
            <>
              <IconButton onClick={toggleDropdownTabs}>
                <ArrowDropDownIcon />
              </IconButton>
              {popover}
            </>
          )}
        </Row>

        <Row id="header-actions" items="center" gap={16}>
          {Action}
        </Row>
      </header>

      <div className={clsx(styles.innerContent, className)}>{children}</div>
    </div>
  )
}

export default WithHeaderTemplate
