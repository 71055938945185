import { FC } from 'react'

import { Row, Text } from '@/components/atoms'
import { FormSectionType } from '@/features/forms/enums'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './AddFormSectionButton.module.scss'
import { formSectionNameMapper } from '@/features/forms/utils'

interface Props {
  type: FormSectionType
  onClick: (type: FormSectionType) => void
}

const AddFormSectionButton: FC<Props> = (props) => {
  const { type, onClick } = props

  const text = `Add ${formSectionNameMapper[type]}`

  return (
    <Row
      items="center"
      justify="center"
      className={styles.addBtn}
      onClick={() => onClick(type)}
    >
      <Text
        type={TextTypes.TEXT_SM}
        weight={FontWeight.SEMIBOLD}
        color={Color.green500}
      >
        + {text}
      </Text>
    </Row>
  )
}

export default AddFormSectionButton
