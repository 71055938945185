import { baseApi, withParams } from '@/api'

import { HttpMethod } from '@/api/enums'
import {
  UpdateISRRecordRequest,
  UpdateISRRecordResponse
} from '@/features/isr/api/types'
import endpoints from './endpoints'

export const isrApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateISRRecord: builder.mutation<
      UpdateISRRecordResponse,
      UpdateISRRecordRequest
    >({
      query: ({ params, body }) => {
        return {
          url: withParams(endpoints.UPDATE_ISR_RECORD, params),
          method: HttpMethod.POST,
          body
        }
      }
    })
  })
})

export const { useUpdateISRRecordMutation } = isrApi
