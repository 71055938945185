import { AssetChainType } from '@/features/gate/enums'
import { PowerUnitTypes } from '@/types/enums/transactionDetails'

const powerUnitTypeByAssetChainType: {
  [key in AssetChainType]: PowerUnitTypes | undefined
} = {
  [AssetChainType.CommercialSeparable]: PowerUnitTypes.Tractor,
  [AssetChainType.CommercialNonSeparable]: PowerUnitTypes.BoxTruckOrSprinterVan,
  [AssetChainType.YardSpotter]: undefined,
  [AssetChainType.Personal]: undefined
}

export default powerUnitTypeByAssetChainType
