import { useMemo, useState } from 'react'

import CargoAssetOnSite from '@/assets/icons/cargo_asset_on_site.svg?react'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { useFetchAccountsQuery } from '@/features/gate/api'
import { EditCargoAssetOnsiteModal } from '@/features/yard'
import {
  cargoAssetsFilters,
  cargoAssetsTableShape
} from '@/features/yard/utils'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { TableActions } from '@/types/enums/table'

import { useQuery } from '@apollo/client'
import { GetCargoAssetsOnsiteQuery } from '@/features/gate/api/queries'
import {
  GateTransaction,
  OrderByDomainEventColumn,
  SearchDomainEventColumns,
  StringOperator
} from '@/__generated__/graphql'
import { TableRequestDetails } from '@/types/interfaces/table'
import { prepareSortForGraphQlApi } from '@/api'
import { useCheckMyPermissionsQuery } from '@/features/auth/api'
import { LaneRelation, PermissionResourceType } from '@/features/auth/enums'
import { preparePermissionResourceQuery } from '@/features/auth/helpers'

const CargoAssetTemplate = () => {
  const [selectedItem, setSelectedItem] = useState<
    GateTransaction | undefined
  >()

  const { selectedPortal, org } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [
    { currentPage, search, sortBy, filters, dateRange },
    setRequestDetails
  ] = useState<TableRequestDetails>({
    currentPage: 1,
    search: '',
    sortBy: undefined,
    filters: undefined,
    dateRange: undefined
  })

  const { loading, data, refetch } = useQuery(GetCargoAssetsOnsiteQuery, {
    variables: {
      input: {
        organizationID: org_id,
        siteID: site_id,
        currentPage,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
        search: {
          fields: [
            SearchDomainEventColumns.CargoAssetOwnerId,
            SearchDomainEventColumns.ChassisId,
            SearchDomainEventColumns.CargoAssetLicensePlateNumber,
            SearchDomainEventColumns.CargoAssetCarrierName,
            SearchDomainEventColumns.PowerUnitCarrierName,
            SearchDomainEventColumns.ShipmentNumber,
            SearchDomainEventColumns.SealNumbers,
            SearchDomainEventColumns.AccountName
          ],
          operator: StringOperator.Contains,
          value: search
        },
        order: prepareSortForGraphQlApi<OrderByDomainEventColumn>(sortBy),
        filter: filters,
        dateRange
      }
    }
  })

  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id
  })

  const filtersList = useMemo(() => cargoAssetsFilters(accounts), [accounts])

  const assets = data?.listCargoAssetsOnSite?.assets || []
  const laneId = assets?.[0]?.metadata?.laneId

  const { currentData: updatePermission, isFetching } =
    useCheckMyPermissionsQuery(
      {
        orgId: org_id,
        relation: LaneRelation.CreateLaneEvent,
        resource: preparePermissionResourceQuery(
          PermissionResourceType.Lane,
          laneId
        )
      },
      { skip: !laneId }
    )

  const isUpdateAllowed = updatePermission?.data?.has_permission

  const { visible, openModal, closeModal } = useModal(false)

  const handleAction = (action: TableActions, row: GateTransaction) => {
    switch (action) {
      case TableActions.RowClick: {
        if (!isUpdateAllowed) return

        setSelectedItem(row)
        openModal()
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <>
      {visible && (
        <EditCargoAssetOnsiteModal
          item={selectedItem!}
          closeModal={closeModal}
          refetch={refetch}
        />
      )}

      <WithHeaderTemplate title="Cargo Assets On Site">
        <div className="tw-flex-1 tw-flex">
          <Table
            withSearch
            idAccessor="id"
            name="cargo-assets"
            rows={assets}
            clickable={isUpdateAllowed}
            loading={loading || isFetching}
            handleAction={handleAction}
            filters={filtersList}
            columns={cargoAssetsTableShape}
            searchPlaceholder="Search Cargo Asset"
            perPage={PAGINATION_ITEMS_PER_PAGE}
            currentPage={currentPage}
            totalPages={data?.listCargoAssetsOnSite?.pagination?.totalPages}
            totalItems={
              data?.listCargoAssetsOnSite?.pagination?.totalItemsCount
            }
            onUpdateRequestDetails={setRequestDetails}
            noResultsMessage={{
              primaryText: 'No cargo assets on site',
              descriptionText:
                'Cargo assets that have Checked-In but have not Checked-Out will appear here.',
              Icon: <CargoAssetOnSite />
            }}
          />
        </div>
      </WithHeaderTemplate>
    </>
  )
}

export default CargoAssetTemplate
