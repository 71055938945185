import { DateFormat } from '@/types/enums/ui'
import {
  IDateRangeFilterValue,
  IFilterDateRange
} from '@/types/interfaces/table'
import { formatDate } from '@/utils/helpers'

const getDateRangeFilterValue = (
  filter: IFilterDateRange,
  value: IDateRangeFilterValue
): string => {
  const startDate = formatDate(value?.startDate, DateFormat.DATE, 'N/A')
  const endDate = formatDate(value?.endDate, DateFormat.DATE, 'N/A')

  return `${startDate} - ${endDate}`
}

export default getDateRangeFilterValue
