import React, { FC, ReactNode, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { AddFormSectionButton, FormSection } from '@/features/forms/components'
import { FORM_IDS, FORM_SECTION_ANCHORS } from '@/features/forms/constants'
import { FormSectionType } from '@/features/forms/enums'
import {
  isAssetEmpty,
  isCargoAssetAllowed,
  isCargoAssetMinimized,
  isChassisIdVisible
} from '@/features/gate/utils'

import {
  AssetCarrier,
  ChassisId,
  InspectionCompleted,
  LicensePlateNumber,
  LicensePlateState,
  LoadStatus,
  OwnerId,
  SealMatchesPW,
  SealNumbers,
  ShipmentNumber,
  Type
} from './fields'

interface IChildrenProps {
  isMinimized: boolean
  isChassisVisible: boolean
  isEmpty: boolean
}

interface IProps {
  title?: string
  isPresent?: boolean
  collapsed?: boolean
  onAdd?: (type: FormSectionType) => void
  onRemove?: () => void
  children: FC<IChildrenProps> | ReactNode
}

const CargoAssetFormSection = (props: IProps) => {
  const {
    children,
    onAdd,
    onRemove,
    collapsed,
    isPresent = true,
    title = 'Cargo Asset'
  } = props

  const { watch } = useFormContext()

  const appointmentType = watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE)
  const powerUnitType = watch(FORM_IDS.POWER_UNIT.TYPE)
  const assetType = watch(FORM_IDS.CARGO_ASSET.TYPE)
  const loadStatus = watch(FORM_IDS.CARGO_ASSET.LOAD_STATUS)

  const isAllowed = isCargoAssetAllowed(appointmentType, powerUnitType)

  const childrenProps = useMemo(
    () => ({
      isMinimized: isCargoAssetMinimized(powerUnitType),
      isChassisVisible: isChassisIdVisible(powerUnitType, assetType),
      isEmpty: isAssetEmpty(loadStatus)
    }),
    [powerUnitType, assetType, loadStatus]
  )

  if (!isAllowed) return null

  if (isPresent) {
    return (
      <FormSection
        id={FORM_SECTION_ANCHORS.CARGO_ASSET.ID}
        onRemove={onRemove}
        title={title}
        collapsed={collapsed}
      >
        {typeof children === 'function' ? children(childrenProps) : children}
      </FormSection>
    )
  }

  return onAdd ? (
    <AddFormSectionButton type={FormSectionType.CargoAsset} onClick={onAdd} />
  ) : null
}

CargoAssetFormSection.Type = Type
CargoAssetFormSection.OwnerId = OwnerId
CargoAssetFormSection.AssetCarrier = AssetCarrier
CargoAssetFormSection.LoadStatus = LoadStatus
CargoAssetFormSection.ShipmentNumber = ShipmentNumber
CargoAssetFormSection.InspectionCompleted = InspectionCompleted
CargoAssetFormSection.ChassisId = ChassisId
CargoAssetFormSection.SealNumbers = SealNumbers
CargoAssetFormSection.SealMatchesPW = SealMatchesPW
CargoAssetFormSection.LicensePlateNumber = LicensePlateNumber
CargoAssetFormSection.LicensePlateState = LicensePlateState

export default CargoAssetFormSection
