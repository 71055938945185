import { FieldErrors } from 'react-hook-form'

import { scrollIntoView } from '@/utils/helpers/index'

const scrollToFirstFormError = (errors: FieldErrors) => {
  const fieldNames = Object.keys(errors || {})

  if (!fieldNames.length) return

  const firstErrorFieldKey = fieldNames[0]
  const firstErrorFieldElement =
    document.getElementsByName(firstErrorFieldKey)?.[0]

  scrollIntoView(firstErrorFieldElement)
}

export default scrollToFirstFormError
