import { TableFilterType } from '@/types/enums/table'
import { IFilterItem } from '@/types/interfaces/table'
import {
  classListSelectOptions,
  emissionTypesSelectOptions,
  fuelTypeSelectOptions,
  isVisitorSelectOptions,
  loadStatusSelectOptions,
  mismatchSelectOptions,
  transactionTypesSelectOptions
} from '@/utils/mappers'
import {
  listToArrayFilter,
  listToStringFilter,
  rangeToDateRangeFilter,
  referenceIdToStringFilter
} from '@/utils/table/filterRequestFormatters'
import { DetectionType } from '@/features/gate/enums'

const transactionsFilters = (accounts: string[]): IFilterItem[] => [
  {
    id: 'dateRange',
    name: 'Date Range',
    type: TableFilterType.DateRange,
    requestFormatter: rangeToDateRangeFilter
  },
  {
    id: 'transactionType',
    name: 'Direction',
    type: TableFilterType.List,
    placeholder: 'Search Direction',
    requestFormatter: listToStringFilter,
    options: transactionTypesSelectOptions
  },
  {
    id: 'referenceId',
    name: 'Event Type',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    requestFormatter: referenceIdToStringFilter,
    options: [
      { id: DetectionType.Detection, label: 'Detection' },
      { id: DetectionType.Manual, label: 'Manual' }
    ]
  },
  {
    id: 'appointmentType',
    name: 'Visitor',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    requestFormatter: listToStringFilter,
    options: isVisitorSelectOptions
  },
  {
    id: 'powerUnitWeightClass',
    name: 'Class',
    type: TableFilterType.List,
    placeholder: 'Search Class',
    requestFormatter: listToStringFilter,
    options: classListSelectOptions
  },
  {
    id: 'powerUnitFuelType',
    name: 'Fuel Type',
    type: TableFilterType.List,
    placeholder: 'Search Fuel Type',
    requestFormatter: listToStringFilter,
    options: fuelTypeSelectOptions
  },
  {
    id: 'powerUnitEmissionType',
    name: 'Fuel Emission Type',
    type: TableFilterType.List,
    placeholder: 'Search Emission Type',
    requestFormatter: listToStringFilter,
    options: emissionTypesSelectOptions
  },
  {
    id: 'loadStatus',
    name: 'Load Status',
    type: TableFilterType.List,
    placeholder: 'Search Status',
    requestFormatter: listToStringFilter,
    options: loadStatusSelectOptions
  },
  {
    id: 'accountName',
    name: 'Account',
    type: TableFilterType.List,
    requestFormatter: listToArrayFilter,
    placeholder: 'Search Account',
    options: accounts.map((account) => ({
      id: account,
      label: account
    }))
  },
  {
    id: 'mismatch',
    name: 'Mismatches',
    type: TableFilterType.List,
    placeholder: 'Search Value',
    requestFormatter: listToStringFilter,
    options: mismatchSelectOptions
  }
]

export default transactionsFilters
