import { FC, ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'

import { Col, ReadOnlyFormValue } from '@/components/atoms'
import {
  FormRow,
  FormSection,
  TruckImageWithLoader
} from '@/features/forms/components'
import { FORM_IDS, FORM_SECTION_ANCHORS } from '@/features/forms/constants'
import { DateFormat } from '@/types/enums/ui'
import { formatDate } from '@/utils/helpers'

import styles from './FormAside.module.scss'

interface Props {
  image: string | undefined
  laneName: string
  showImage: boolean
  Action?: ReactNode
}

const FormAside: FC<Props> = (props) => {
  const { showImage, image, laneName, Action } = props

  const { watch } = useFormContext() || {}
  const date = watch(FORM_IDS.GENERAL.DATE)

  return (
    <FormSection
      collapsible={false}
      id={FORM_SECTION_ANCHORS.GENERAL.ID}
      title="General"
    >
      <Col gap={16} className={styles.formAside}>
        <TruckImageWithLoader
          title={laneName}
          showImage={showImage}
          image={image}
        />

        <Col gap={16} className={styles.fieldsWrapper}>
          <FormRow className={styles.dateAndTime}>
            <ReadOnlyFormValue
              title="Date"
              value={formatDate(date, DateFormat.DATE)}
            />
            <ReadOnlyFormValue
              title="Time"
              value={formatDate(date, DateFormat.TIME)}
            />
          </FormRow>

          <FormRow className={styles.laneAndActions}>
            <ReadOnlyFormValue title="Lane" value={laneName} />

            {!!Action && <div className={styles.actionWrapper}>{Action}</div>}
          </FormRow>
        </Col>
      </Col>
    </FormSection>
  )
}

export default FormAside
