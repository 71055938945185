import { yupResolver } from '@hookform/resolvers/yup'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import React, { FC, Fragment, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button } from '@/components/atoms'
import { TwoColumnModalWrapper } from '@/components/molecules'
import {
  DriverDetailsFormSection,
  FormAside,
  ModalWithForm,
  SelectFirstSection
} from '@/features/forms/components'
import { YUP_CONTEXT_VARIABLES } from '@/features/forms/constants'
import { FormSectionType } from '@/features/forms/enums'
import { CheckInCargoAsset, CheckInPowerUnit } from '@/features/gate'
import {
  useCreateGateTransactionMutation,
  useGetPresignedUrlQuery
} from '@/features/gate/api'
import {
  CreateGateTransactionRequestBody,
  CreateGateTransactionRequestParams
} from '@/features/gate/api/types'
import {
  DomainEventSchema,
  DomainEventTypes,
  DomainTransactionTypes,
  LaneDirection
} from '@/features/gate/enums'
import { useFormSectionsPresent } from '@/features/gate/hooks'
import { GateQueueEvent, Lane } from '@/features/gate/types'
import {
  GateTransactionSchema,
  GateTransactionSchemaType,
  getFormValuesFromQueueEvent,
  prepareGateTransactionMetadata
} from '@/features/gate/utils'
import { useStore } from '@/store'
import { scrollToFirstFormError } from '@/utils/helpers'

interface IProps {
  item?: GateQueueEvent
  lane: Lane
  gateId: string
  closeModal: (removeFromMovedItems?: boolean) => void
  onSwapLane: (
    item: GateQueueEvent,
    to: LaneDirection,
    reopenModal?: boolean
  ) => void
}

const CheckInModal: FC<IProps> = (props) => {
  const { item, closeModal, onSwapLane, lane, gateId } = props

  const { selectedPortal, org } = useStore((store) => store.user)

  const org_id = org?.organization_id || ''
  const site_id = selectedPortal?.id || ''

  const [createGateTransaction, { isLoading }] =
    useCreateGateTransactionMutation()

  const { data: imageUrl } = useGetPresignedUrlQuery(
    { org_id, site_id, event_id: item?.id || '' },
    { skip: !item }
  )

  const {
    selectSection,
    removeCargoAsset,
    removePowerUnitAndDriver,
    isSectionPresent,
    isEmptyForm
  } = useFormSectionsPresent(item)

  const formReturn = useForm<GateTransactionSchemaType>({
    resolver: yupResolver(GateTransactionSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: getFormValuesFromQueueEvent(item),
    shouldFocusError: false,

    context: {
      [YUP_CONTEXT_VARIABLES.HAS_POWER_UNIT_AND_DRIVER]:
        isSectionPresent.powerUnitAndDriverDetails,
      [YUP_CONTEXT_VARIABLES.HAS_CARGO_ASSET]:
        isSectionPresent.cargoAssetDetails
    }
  })

  const {
    reset,
    handleSubmit,
    formState: { submitCount, errors }
  } = formReturn

  const isManual = !item

  const onSubmit = async (formData: GateTransactionSchemaType) => {
    if (!org_id || !site_id) return

    const metadata = prepareGateTransactionMetadata({
      formData,
      initialMetadata: {
        lane_id: lane.id,
        transaction_type: DomainTransactionTypes.CheckIn
      },
      hasPowerUnitAndDriver: isSectionPresent.powerUnitAndDriverDetails,
      hasCargoAsset: isSectionPresent.cargoAssetDetails
    })

    const cargoAssetOnly =
      !isSectionPresent.powerUnitAndDriverDetails &&
      isSectionPresent.cargoAssetDetails

    const query: CreateGateTransactionRequestBody = {
      reference_id: item?.correlation_id || '',
      transaction_type: DomainTransactionTypes.CheckIn,
      type: cargoAssetOnly
        ? DomainEventTypes.ManualOnsiteCargoAssetCheckIn
        : DomainEventTypes.ManualCheckIn,
      schema: DomainEventSchema.September2024,
      metadata,
      metadata_raw: item?.metadata?.AssetChainEvent || {}
    }

    const params: CreateGateTransactionRequestParams = {
      org_id,
      site_id,
      gate_id: gateId,
      lane_id: lane.id
    }

    const response = await createGateTransaction({
      params,
      body: query
    })

    if (!response.error) {
      closeModal(true)
    }
  }

  useEffect(() => {
    scrollToFirstFormError(errors)
  }, [submitCount])

  const formSections = {
    [FormSectionType.PowerUnitAndDriver]: (
      <Fragment key={FormSectionType.PowerUnitAndDriver}>
        <CheckInPowerUnit
          siteId={site_id}
          isManual={isManual}
          isPresent={isSectionPresent.powerUnitAndDriverDetails}
          suggestedIds={item?.metadata?.power_unit_owner_id || []}
          suggestedUsdot={item?.metadata?.power_unit_carrier_usdot || []}
          onAdd={selectSection}
          onRemove={
            isManual ? () => removePowerUnitAndDriver(reset) : undefined
          }
        />

        {!isManual && <DriverDetailsFormSection />}
      </Fragment>
    ),

    [FormSectionType.CargoAsset]: (
      <CheckInCargoAsset
        key={FormSectionType.CargoAsset}
        onAdd={selectSection}
        onRemove={() => removeCargoAsset(reset)}
        isPresent={isSectionPresent.cargoAssetDetails}
        suggestedIds={item?.metadata?.cargo_asset_owner_id || []}
      />
    )
  }

  return (
    <FormProvider {...formReturn}>
      <ModalWithForm
        title="Check-In"
        closeModal={closeModal}
        isLoading={isLoading}
        isSectionPresent={isSectionPresent}
        onAddSection={selectSection}
        submitDisabled={isEmptyForm}
        buttonText="Complete Check-In"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TwoColumnModalWrapper
          leftSide={
            <FormAside
              showImage={!!item}
              image={imageUrl}
              laneName={lane.display_name}
              Action={
                !!item && (
                  <Button
                    type="outlined"
                    startIcon={<SwapHorizOutlinedIcon />}
                    onClick={() =>
                      onSwapLane(item, LaneDirection.Departing, true)
                    }
                  >
                    Move to Check-Out
                  </Button>
                )
              }
            />
          }
        >
          <form>
            {isEmptyForm ? (
              <SelectFirstSection
                direction={LaneDirection.Arriving}
                onSelect={selectSection}
              />
            ) : (
              <>
                {/* Sections can be added by a user in any order meaning we need to map over  */}
                {/* the present sections and return their respective components  */}
                {Object.keys(isSectionPresent).map(
                  (type) => formSections[type as FormSectionType]
                )}
              </>
            )}
          </form>
        </TwoColumnModalWrapper>
      </ModalWithForm>
    </FormProvider>
  )
}

export default CheckInModal
