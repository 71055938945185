export const GENERAL = {
  DATE: 'date',
  APPOINTMENT_TYPE: 'appointment_type'
} as const

const POWER_UNIT = {
  TYPE: 'power_unit_type',
  OWNER_ID: 'power_unit_owner_id',
  USDOT: 'power_unit_carrier_usdot',
  CARRIER: 'power_unit_carrier_name',
  ACCOUNT: 'account_name',
  LPN: 'power_unit_license_plate_number',
  LPN_STATE: 'power_unit_license_plate_state',
  VIN: 'power_unit_vin',
  WEIGHT_CLASS: 'power_unit_weight_class',
  FUEL_TYPE: 'power_unit_fuel_type'
} as const

const CARGO_ASSET = {
  TYPE: 'cargo_asset_asset_type',
  OWNER_ID: 'cargo_asset_owner_id',
  CARRIER: 'cargo_asset_carrier_name',
  LOAD_STATUS: 'load_status',
  SHIPMENT_NUMBER: 'shipment_number',
  INSPECTION_COMPLETED: 'inspection_completed',
  CHASSIS_ID: 'chassis_id',
  SEAL_NUMBERS: 'seal_numbers',
  SEAL_MATCHES_PW: 'seal_matchPW',
  LPN: 'cargo_asset_license_plate_number',
  LPN_STATE: 'cargo_asset_license_plate_state'
} as const

const DRIVER_DETAILS = {
  FIRST_NAME: 'driver_first_name',
  LAST_NAME: 'driver_last_name',
  FULL_NAME: 'driver_full_name',
  DRIVER_ID: 'driver_license_number',
  LICENSE_STATE: 'driver_license_state',
  PHONE: 'driver_phone_number'
} as const

export const FORM_IDS = {
  GENERAL,
  POWER_UNIT,
  CARGO_ASSET,
  DRIVER_DETAILS
}

export const FORM_SECTION_ANCHORS = {
  GENERAL: {
    ID: 'form-section-general',
    TITLE: 'General'
  },
  POWER_UNIT: {
    ID: 'form-section-power-unit',
    TITLE: 'Power Unit'
  },
  DRIVER: {
    ID: 'form-section-driver-details',
    TITLE: 'Driver'
  },
  CARGO_ASSET: {
    ID: 'form-section-cargo-asset',
    TITLE: 'Cargo Asset'
  }
}

export const YUP_CONTEXT_VARIABLES = {
  HAS_POWER_UNIT_AND_DRIVER: 'hasPowerUnitAndDriver',
  HAS_CARGO_ASSET: 'hasCargoAsset'
}

export const MAX_LENGTHS = {
  OWNER_ID: 50,
  LPN: 20,
  VIN: 20,
  USDOT: 20,

  DRIVER_NAME: 20,
  DRIVER_LICENSE_NUMBER: 25,

  CHASSIS_ID: 25,
  SHIPMENT_NUMBER: 25,
  SEAL_NUMBER: 25
}

export const MIN_LENGTHS = {
  LPN: 1,
  USDOT: 3
}
