import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounceValue } from 'usehooks-ts'

import { Input, ReadOnlyFormValue } from '@/components/atoms'
import { DEBOUNCE_TIME, MIN_LPN_LENGTH, MIN_VIN_LENGTH } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { FailedToLoadFields } from '@/features/forms/types'
import {
  useLazyGetClassAndFuelQuery,
  useLazyGetVinQuery
} from '@/features/gate/api'
import { useStore } from '@/store'
import { FuelTypes, WeightClasses } from '@/types/enums/transactionDetails'
import { ReadOnlyProps } from '@/types/interfaces/ui'

interface IProps {
  allowApiCalls?: boolean | ((isDirty: boolean) => boolean)
  setFieldsFailedToLoad?: Dispatch<SetStateAction<FailedToLoadFields>>
}

const VinNumber: FC<IProps | ReadOnlyProps> = (props) => {
  const { setFieldsFailedToLoad, allowApiCalls } = props as IProps
  const { readOnly, label = 'VIN' } = props as ReadOnlyProps

  const {
    control,
    watch,
    setValue,
    formState: { isDirty }
  } = useFormContext()
  const orgId = useStore((store) => store.user.org?.organization_id || '')

  const [getVin, { isFetching }] = useLazyGetVinQuery()
  const [getClassAndFuelType] = useLazyGetClassAndFuelQuery()

  const lpn = watch(FORM_IDS.POWER_UNIT.LPN)
  const lpnState = watch(FORM_IDS.POWER_UNIT.LPN_STATE)
  const vinNumber = watch(FORM_IDS.POWER_UNIT.VIN)

  const [vinDebounced] = useDebounceValue(vinNumber, DEBOUNCE_TIME)
  const [lpnDebounced] = useDebounceValue(lpn, DEBOUNCE_TIME)

  const isFetchAllowed = useMemo(() => {
    if (typeof allowApiCalls === 'boolean') return allowApiCalls
    if (typeof allowApiCalls === 'function') return allowApiCalls(isDirty)

    return true
  }, [allowApiCalls, isDirty])

  const fetchAndUpdateVin = async () => {
    const isLpnReady =
      lpnDebounced && lpnDebounced?.length >= MIN_LPN_LENGTH && lpnState

    if (!orgId || !isLpnReady) return

    const response = await getVin({
      org_id: orgId,
      license_plate_number: lpnDebounced,
      state: lpnState
    })

    const vin = response?.data?.data?.vin || ''

    if (!vin) {
      setFieldsFailedToLoad?.({
        fuelType: true,
        weightClass: true
      })

      setValue(FORM_IDS.POWER_UNIT.FUEL_TYPE, '' as FuelTypes)
      setValue(FORM_IDS.POWER_UNIT.WEIGHT_CLASS, '' as WeightClasses)
    }

    setValue(FORM_IDS.POWER_UNIT.VIN, vin, {
      shouldValidate: !!vin,
      shouldDirty: true
    })
  }

  const fetchAndUpdateClassAndFuel = async () => {
    const isVinReady = vinDebounced && vinDebounced?.length >= MIN_VIN_LENGTH

    if (!orgId || !isVinReady) return

    const response = await getClassAndFuelType({
      org_id: orgId,
      vin: vinDebounced
    })

    const vehicleClass = response?.data?.data?.vehicle_class as WeightClasses
    const fuelType = response?.data?.data?.fuel_type as FuelTypes

    setFieldsFailedToLoad?.({
      fuelType: !fuelType,
      weightClass: !vehicleClass
    })

    setValue(FORM_IDS.POWER_UNIT.WEIGHT_CLASS, vehicleClass || '', {
      shouldValidate: !!vehicleClass
    })
    setValue(FORM_IDS.POWER_UNIT.FUEL_TYPE, fuelType || '', {
      shouldValidate: !!fuelType
    })
  }

  useEffect(() => {
    if (isFetchAllowed) {
      fetchAndUpdateVin()
    }
  }, [lpnDebounced, lpnState])

  useEffect(() => {
    if (isFetchAllowed) {
      fetchAndUpdateClassAndFuel()
    }
  }, [vinDebounced])

  if (isFetching) return <ReadOnlyFormValue loading title={label} />

  if (readOnly) return <ReadOnlyFormValue title={label} value={vinNumber} />

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.VIN}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          uppercase
          fullWidth
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default VinNumber
