import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Chip } from '@/components/atoms'
import { TwoColumnModalWrapper } from '@/components/molecules'
import {
  DriverDetailsFormSection,
  FormAside,
  ModalWithForm
} from '@/features/forms/components'
import { YUP_CONTEXT_VARIABLES } from '@/features/forms/constants'
import { CheckInCargoAsset, CheckInPowerUnit } from '@/features/gate'
import {
  useGetPresignedUrlQuery,
  useUpdateGateTransactionMutation
} from '@/features/gate/api'
import {
  UpdateGateTransactionRequestBody,
  UpdateGateTransactionRequestParams
} from '@/features/gate/api/types'
import { useFormSectionsPresent } from '@/features/gate/hooks'
import {
  GateTransactionSchema,
  GateTransactionSchemaType,
  getFormValuesFromGateTransaction,
  prepareGateTransactionMetadata
} from '@/features/gate/utils'
import { useSnackbar } from '@/features/snackbars-queue'
import { useStore } from '@/store'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { scrollToFirstFormError } from '@/utils/helpers'
import {
  GateTransaction,
  ListCargoAssetsOnsiteQuery
} from '@/__generated__/graphql'
import { ApolloQueryResult } from '@apollo/client'

interface IProps extends IModalWithCloseFn {
  item: GateTransaction
  refetch: () => Promise<ApolloQueryResult<ListCargoAssetsOnsiteQuery>>
}

const EditCargoAssetOnsiteModal: FC<IProps> = (props) => {
  const { item, refetch, closeModal } = props

  const { metadata, id, referenceId, laneDisplayName } = item
  const { mismatch, laneId } = metadata

  const { selectedPortal, org, selectedGate } = useStore((store) => store.user)
  const { showErrorSnackbar } = useSnackbar()

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const { data: imageUrl } = useGetPresignedUrlQuery(
    {
      org_id,
      site_id,
      event_id: id
    },
    { skip: !referenceId }
  )
  const [updateGateTransaction, { isLoading }] =
    useUpdateGateTransactionMutation()
  const { isSectionPresent } = useFormSectionsPresent(item)

  const formReturn = useForm<GateTransactionSchemaType>({
    resolver: yupResolver(GateTransactionSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: getFormValuesFromGateTransaction(item),

    context: {
      [YUP_CONTEXT_VARIABLES.HAS_POWER_UNIT_AND_DRIVER]:
        isSectionPresent.powerUnitAndDriverDetails,
      [YUP_CONTEXT_VARIABLES.HAS_CARGO_ASSET]:
        isSectionPresent.cargoAssetDetails
    }
  })

  const {
    handleSubmit,
    formState: { submitCount, errors }
  } = formReturn

  const onSubmit = async (formData: GateTransactionSchemaType) => {
    if (!org_id || !site_id) return

    if (!selectedGate) {
      showErrorSnackbar('Gate not found')
      return
    }

    const query: UpdateGateTransactionRequestBody = {
      correlation_id: item.correlationId,
      metadata: prepareGateTransactionMetadata({
        formData,
        initialMetadata: item.metadata,
        hasPowerUnitAndDriver: isSectionPresent.powerUnitAndDriverDetails,
        hasCargoAsset: isSectionPresent.cargoAssetDetails
      })
    }

    const params: UpdateGateTransactionRequestParams = {
      org_id,
      site_id,
      gate_id: selectedGate.id,
      lane_id: laneId
    }

    const response = await updateGateTransaction({
      params,
      body: query
    })

    if (!response.error) {
      closeModal()
      refetch()
    }
  }

  useEffect(() => {
    scrollToFirstFormError(errors)
  }, [submitCount])

  return (
    <FormProvider {...formReturn}>
      <ModalWithForm
        title="Cargo Asset Details"
        closeModal={closeModal}
        isLoading={isLoading}
        isSectionPresent={isSectionPresent}
        onSubmit={handleSubmit(onSubmit)}
        header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
      >
        <TwoColumnModalWrapper
          leftSide={
            <FormAside
              showImage={!!referenceId}
              image={imageUrl}
              laneName={laneDisplayName || '-'}
            />
          }
        >
          <form>
            <CheckInCargoAsset isPresent showCarrier />

            {isSectionPresent.powerUnitAndDriverDetails && (
              <>
                <CheckInPowerUnit
                  isPresent
                  readOnly
                  collapsed
                  allowApiCalls={false}
                  siteId={site_id}
                />
                <DriverDetailsFormSection readOnly collapsed />
              </>
            )}
          </form>
        </TwoColumnModalWrapper>
      </ModalWithForm>
    </FormProvider>
  )
}

export default EditCargoAssetOnsiteModal
