export enum ErrorType {
  Generic = 'generic',
  NotFound = '404',
  Unavailable = '503'
}

export enum TextTypes {
  DISPLAY_XL = 'display-xl',
  DISPLAY_LG = 'display-lg',
  DISPLAY_MD = 'display-md',
  DISPLAY_SM = 'display-sm',
  DISPLAY_XS = 'display-xs',
  TEXT_XL = 'text-xl',
  TEXT_LG = 'text-lg',
  TEXT_MD = 'text-md',
  TEXT_SM = 'text-sm',
  TEXT_XS = 'text-xs'
}

export enum FontWeight {
  REGULAR = 'font-regular',
  SEMIBOLD = 'font-semibold',
  BOLD = 'font-bold',
  BLACK = 'font-black'
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify'
}

export enum DateFormat {
  DATE = 'MM/DD/YYYY',
  TIME = 'hh:mm A',
  DATE_TIME = 'MM/DD/YYYY, hh:mm A'
}

export enum KeyCode {
  Enter = 'Enter',
  Comma = ','
}

export enum TimeUnit {
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Millisecond = 'millisecond'
}
