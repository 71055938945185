import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import config from '@/config'
import { RootState } from '@/store'
import { ApolloClient, InMemoryCache } from '@apollo/client'

export * from './helpers'

const prepareHeaders = (
  headers: Headers,
  token: string | undefined,
  isRestApi?: boolean
): Headers => {
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)

    if (isRestApi) {
      headers.set('Cf-Access-Jwt-Assertion', token)
      headers.set('Content-Type', 'application/json')
    }
  }

  return headers
}

export const baseApi = createApi({
  reducerPath: 'api',
  tagTypes: ['User', 'Invite', 'Site', 'Role', 'SiteRole', 'Event'],
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.authToken

      prepareHeaders(headers, token, true)

      return headers
    }
  }),
  endpoints: () => ({})
})

export const apolloClient = (authToken: string | undefined) =>
  new ApolloClient({
    uri: config.graphqlApiUrl,
    cache: new InMemoryCache(),
    headers: Object.fromEntries(prepareHeaders(new Headers(), authToken)),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }
    }
  })
