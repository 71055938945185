import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { get } from 'lodash'
import { TimeUnit } from '@/types/enums/ui'

dayjs.extend(duration)

const millisecondsToDuration = (row: any, id: string) => {
  const value = get(row, id)

  if (!value) return '-'

  const durationValue = dayjs.duration(value, TimeUnit.Millisecond)

  const days = durationValue.days()
  const hours = durationValue.hours()
  const minutes = durationValue.minutes()
  const seconds = durationValue.seconds()

  const result = []

  if (days) {
    result.push(`${days}d`)
  }

  if (hours) {
    result.push(`${hours}h`)
  }

  if (minutes) {
    result.push(`${minutes}m`)
  }

  if (seconds) {
    result.push(`${seconds}s`)
  }

  return result.join(' ')
}

export default millisecondsToDuration
