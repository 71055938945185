export enum PermissionResourceType {
  Organization = 'organization',
  Site = 'site',
  Gate = 'gate',
  Lane = 'lane'
}

export enum OrganizationRelation {
  EnterprisePortal = 'enterprise_portal'
}

export enum SiteRelation {
  GateTransactionsList = 'list_gate_transactions',
  DriversOnsiteList = 'list_drivers_onsite',
  CargoAssetsOnsiteList = 'list_cargo_assets_onsite',
  ISRRecordsList = 'list_isr_records'
}

export enum LaneRelation {
  UpdateIsrRecord = 'update_isr_record',
  CreateLaneEvent = 'create_lane_event'
}

export enum GateRelation {
  Read = 'read',
  CheckIn = 'check_in',
  CheckOut = 'check_out'
}
