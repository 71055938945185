import { Collapse } from '@mui/material'
import { FC } from 'react'

import { Col, WarningMessage } from '@/components/atoms'
import {
  DriverDetailsFormSection,
  FormCol,
  FormRow,
  PowerUnitFormSection,
  SuggestedItems
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { FormSectionType } from '@/features/forms/enums'

interface IProps {
  siteId: string
  readOnly?: boolean
  isManual?: boolean
  isPresent: boolean
  collapsed?: boolean
  allowApiCalls?: boolean
  hideAppointmentType?: boolean
  suggestedIds?: string[]
  suggestedUsdot?: string[]
  onAdd?: (type: FormSectionType) => void
  onRemove?: () => void
}

const CheckInPowerUnit: FC<IProps> = (props) => {
  const {
    isPresent,
    collapsed,
    readOnly = false,
    isManual = false,
    allowApiCalls = true,
    hideAppointmentType = false,
    suggestedIds = [],
    suggestedUsdot = [],
    siteId,
    onAdd,
    onRemove
  } = props

  return (
    <PowerUnitFormSection
      isPresent={isPresent}
      onAdd={onAdd}
      onRemove={onRemove}
      collapsed={collapsed}
    >
      {({
        isNotVisitor,
        warningMessage,
        showFuelTypeAndWeightClass,
        setFieldsFailedToLoad
      }) => (
        <FormCol>
          {!hideAppointmentType && (
            <PowerUnitFormSection.VisitorToggle readOnly={readOnly} />
          )}
          <PowerUnitFormSection.Type readOnly={readOnly} />

          {!!warningMessage && (
            <WarningMessage>{warningMessage}</WarningMessage>
          )}

          <FormRow columnOnMobile>
            <FormRow>
              <PowerUnitFormSection.LicensePlateNumber readOnly={readOnly} />
              <PowerUnitFormSection.LicensePlateState readOnly={readOnly} />
            </FormRow>

            <PowerUnitFormSection.VinNumber
              allowApiCalls={allowApiCalls}
              readOnly={readOnly}
              setFieldsFailedToLoad={setFieldsFailedToLoad}
            />
          </FormRow>

          <Collapse in={showFuelTypeAndWeightClass} unmountOnExit>
            <FormRow>
              <PowerUnitFormSection.WeightClass readOnly={readOnly} />
              <PowerUnitFormSection.FuelType readOnly={readOnly} />
            </FormRow>
          </Collapse>

          <Col gap={8}>
            <FormRow>
              <PowerUnitFormSection.UsDOT
                readOnly={readOnly}
                required={isNotVisitor}
              />
              <PowerUnitFormSection.OperatingCarrier
                allowApiCalls={allowApiCalls}
                readOnly={readOnly}
                required={isNotVisitor}
              />
            </FormRow>

            <SuggestedItems
              fieldId={FORM_IDS.POWER_UNIT.USDOT}
              suggestedItems={suggestedUsdot}
            />
          </Col>

          <Col gap={7}>
            <PowerUnitFormSection.OwnerId readOnly={readOnly} />
            <SuggestedItems
              fieldId={FORM_IDS.POWER_UNIT.OWNER_ID}
              suggestedItems={suggestedIds}
            />
          </Col>

          {isNotVisitor && (
            <PowerUnitFormSection.Account siteId={siteId} readOnly={readOnly} />
          )}

          {isManual && (
            <DriverDetailsFormSection
              readOnly={readOnly}
              title="Driver"
              withoutWrapper
            />
          )}
        </FormCol>
      )}
    </PowerUnitFormSection>
  )
}

export default CheckInPowerUnit
