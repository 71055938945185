import KeyIcon from '@/assets/icons/key.svg?react'
import OctaSvg from '@/assets/icons/octo.svg?react'
import { Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextAlign, TextTypes } from '@/types/enums/ui'
import { SsoEnabledState } from '../../types/sso'
import styles from './EnterpriseLanding.module.scss'

interface IProps {
  ssoEnabledState: SsoEnabledState
  openEnableSsoModal: () => void
}

const EnableSsoTile = ({ ssoEnabledState, openEnableSsoModal }: IProps) => {
  const ssoEnabled = ssoEnabledState === SsoEnabledState.Enabled

  const ssoEnabledContent = (
    <>
      <OctaSvg className={styles.blackLogo} />
      <Col gap={8}>
        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.SEMIBOLD}
          align={TextAlign.CENTER}
          color={Color.gray700}
          className={styles.cardTitle}
        >
          Okta SSO
        </Text>
        <Text
          type={TextTypes.TEXT_XS}
          weight={FontWeight.SEMIBOLD}
          align={TextAlign.CENTER}
          color={Color.green500}
        >
          Enabled
        </Text>
      </Col>
    </>
  )

  const ssoNotEnabledContent = (
    <>
      <KeyIcon />
      <Text
        type={TextTypes.TEXT_SM}
        weight={FontWeight.SEMIBOLD}
        align={TextAlign.CENTER}
        color={Color.gray700}
        className={styles.cardTitle}
      >
        Enable SSO
      </Text>
    </>
  )

  const ssoLoadingContent = (
    <>
      <KeyIcon />
      <Text
        type={TextTypes.TEXT_XS}
        weight={FontWeight.SEMIBOLD}
        align={TextAlign.CENTER}
        color={Color.green500}
      >
        Loading...
      </Text>
    </>
  )

  function getCardContent() {
    if (ssoEnabledState === SsoEnabledState.Enabled) return ssoEnabledContent
    if (ssoEnabledState === SsoEnabledState.NotEnabled)
      return ssoNotEnabledContent
    return ssoLoadingContent
  }

  return (
    <div
      className={`${styles.adminToolLink} ${ssoEnabled ? styles.disabled : ''}`}
      onClick={() => {
        if (ssoEnabled) return
        openEnableSsoModal()
      }}
    >
      <Col
        items="center"
        justify="end"
        className={`${styles.card} ${ssoEnabledState !== SsoEnabledState.NotEnabled ? styles.disabledCard : ''}`}
      >
        {getCardContent()}
      </Col>
    </div>
  )
}

export default EnableSsoTile
