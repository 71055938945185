import { Button, Col, Row, Text } from '@/components/atoms'
import { Modal } from '@/components/organisms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { Refresh } from '@mui/icons-material'

const ExpiredSessionModal = () => {
  return (
    <Modal
      title={'Are you still there?'}
      closeModal={close}
      hideCloseButtons
      disableCloseOnBackdropClick
      footer={
        <Row items="stretch" gap={8}>
          <Button
            onClick={() => {
              window.location.reload()
            }}
            type="primary"
            startIcon={<Refresh />}
          >
            Refresh Page
          </Button>
        </Row>
      }
    >
      <Col gap={8}>
        <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
          Your session appears to be inactive. Refresh the page to continue.
        </Text>
      </Col>
    </Modal>
  )
}

export default ExpiredSessionModal
