import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

import { Col } from '@/components/atoms'

import styles from './FormCol.module.scss'

interface Props {
  id?: string
  className?: string
}

const FormCol: FC<PropsWithChildren<Props>> = (props) => {
  const { id, children, className } = props

  return (
    <Col
      id={id}
      gap={20}
      items="stretch"
      className={clsx(styles.formColumn, className)}
    >
      {children}
    </Col>
  )
}

export default FormCol
