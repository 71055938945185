import { FC, useState } from 'react'
import DetectableOverflow from 'react-detectable-overflow'

import { Col, ProgressBar, Text, Tooltip } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'

import styles from './CardRow.module.scss'

interface IProps {
  titleOne: string
  titleTwo: string
  valueOne: string | undefined
  valueTwo: string | undefined
  loadingOne?: boolean
  loadingTwo?: boolean
}

const valueClassNames =
  'tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap color-gray700 text-md'

const CardRow: FC<IProps> = (props) => {
  const { titleOne, titleTwo, valueOne, valueTwo, loadingOne, loadingTwo } =
    props

  const [overflowFirst, setOverflowFirst] = useState<boolean>(false)
  const [overflowSecond, setOverflowSecond] = useState<boolean>(false)

  return (
    <div className={styles.gridRow}>
      <Col gap={4} className="tw-overflow-hidden">
        <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
          {titleOne}
        </Text>

        {loadingOne ? (
          <ProgressBar />
        ) : (
          <Tooltip show={overflowFirst} placement="bottom" tooltip={valueOne}>
            <div>
              <DetectableOverflow
                onChange={setOverflowFirst}
                className={valueClassNames}
              >
                {valueOne || '-'}
              </DetectableOverflow>
            </div>
          </Tooltip>
        )}
      </Col>

      <Col gap={4} className="tw-overflow-hidden">
        <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
          {titleTwo}
        </Text>

        {loadingTwo ? (
          <ProgressBar />
        ) : (
          <Tooltip show={overflowSecond} placement="bottom" tooltip={valueTwo}>
            <div>
              <DetectableOverflow
                onChange={setOverflowSecond}
                className={valueClassNames}
              >
                {valueTwo || '-'}
              </DetectableOverflow>
            </div>
          </Tooltip>
        )}
      </Col>
    </div>
  )
}

export default CardRow
