import { matchIsValidTel } from 'mui-tel-input'
import * as yup from 'yup'

import { MAX_LENGTHS, MIN_LENGTHS } from '@/features/forms/constants'
import { FormLengthErrorType } from '@/features/forms/enums'
import { lengthErrorMessage } from '@/features/forms/utils'
import { isNotVisitor } from '@/features/gate/utils'
import {
  FuelTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'

export const ISRVisitSchema = yup.object({
  // Power Unit Section
  power_unit_owner_id: yup
    .string()
    .max(MAX_LENGTHS.OWNER_ID, lengthErrorMessage(MAX_LENGTHS.OWNER_ID)),
  power_unit_type: yup
    .mixed<PowerUnitTypes>()
    .oneOf(Object.values(PowerUnitTypes))
    .required('Type is required'),
  power_unit_license_plate_number: yup
    .string()
    .trim()
    .min(MIN_LENGTHS.LPN, 'LPN is required')
    .max(MAX_LENGTHS.LPN, lengthErrorMessage(MAX_LENGTHS.LPN))
    .required('LPN is required'),
  power_unit_license_plate_state: yup.string().required('State is required'),
  power_unit_carrier_name: yup.string().when('appointment_type', {
    is: isNotVisitor,
    then: (schema) => schema.required('Carrier is required'),
    otherwise: (schema) => schema
  }),
  power_unit_carrier_usdot: yup.string().when('appointment_type', {
    is: isNotVisitor,
    then: (schema) =>
      schema
        .test(
          'len',
          lengthErrorMessage(MIN_LENGTHS.USDOT, FormLengthErrorType.Min),
          (val) => !!val && val?.toString()?.length >= MIN_LENGTHS.USDOT
        )
        .test(
          'len',
          lengthErrorMessage(MAX_LENGTHS.USDOT),
          (val) => !!val && val?.toString()?.length <= MAX_LENGTHS.USDOT
        )
        .required('US DOT # is required'),
    otherwise: (schema) => schema
  }),
  power_unit_vin: yup
    .string()
    .max(MAX_LENGTHS.VIN, lengthErrorMessage(MAX_LENGTHS.VIN)),
  power_unit_weight_class: yup
    .mixed<WeightClasses>()
    .oneOf(Object.values(WeightClasses), 'Class is required')
    .required('Class is required'),
  power_unit_fuel_type: yup
    .mixed<FuelTypes>()
    .oneOf(Object.values(FuelTypes), 'Fuel Type is required')
    .required('Fuel Type is required'),
  account_name: yup.array().of(yup.string()),

  // Driver Section
  driver_first_name: yup
    .string()
    .max(MAX_LENGTHS.DRIVER_NAME, lengthErrorMessage(MAX_LENGTHS.DRIVER_NAME))
    .required('First Name is required'),
  driver_last_name: yup
    .string()
    .max(MAX_LENGTHS.DRIVER_NAME, lengthErrorMessage(MAX_LENGTHS.DRIVER_NAME))
    .required('Last Name is required'),
  driver_license_number: yup
    .string()
    .max(
      MAX_LENGTHS.DRIVER_LICENSE_NUMBER,
      lengthErrorMessage(MAX_LENGTHS.DRIVER_LICENSE_NUMBER)
    )
    .required('Driver License is required'),
  driver_phone_number: yup
    .string()
    .test('format', 'Not valid phone number', (val) =>
      val
        ? matchIsValidTel(val, {
            onlyCountries: ['US', 'MX', 'CA']
          })
        : true
    )
    .notRequired(),
  driver_license_state: yup.string().required('State is required')
})

export type ISRVisitSchemaType = yup.InferType<typeof ISRVisitSchema>
