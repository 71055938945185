import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { Checkbox } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { ReadOnlyProps } from '@/types/interfaces/ui'

const InspectionCompleted: FC<ReadOnlyProps> = (props) => {
  const { readOnly } = props
  const { watch, setValue } = useFormContext()

  const inspectionCompleted = watch(FORM_IDS.CARGO_ASSET.INSPECTION_COMPLETED)

  const onChange = () =>
    setValue(FORM_IDS.CARGO_ASSET.INSPECTION_COMPLETED, !inspectionCompleted)

  return (
    <Checkbox
      disabled={readOnly}
      value={inspectionCompleted}
      label="Inspection complete"
      onChange={onChange}
    />
  )
}

export default InspectionCompleted
