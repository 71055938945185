import dayjs, { Dayjs } from 'dayjs'
import { DateRangeFilter } from '@/__generated__/graphql'
import { OLDEST_AVAILABLE_DATE } from '@/constants'
import { TimeUnit } from '@/types/enums/ui'

const dwellToDateRangeFilter = (value: Dayjs): DateRangeFilter => ({
  startDate: dayjs(OLDEST_AVAILABLE_DATE)?.toISOString(),
  endDate: dayjs()
    .subtract(value.hour() || 0, TimeUnit.Hour)
    .subtract(value.minute() || 0, TimeUnit.Minute)
    .toISOString()
})

export default dwellToDateRangeFilter
