import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Autocomplete, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { ISelectOption, ReadOnlyProps } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'
import { userFriendlyState } from '@/utils/table'

const LicensePlateState: FC<ReadOnlyProps> = (props) => {
  const { readOnly, label = 'LPN State/Region' } = props
  const { control, watch } = useFormContext()

  const lpnState = watch(FORM_IDS.POWER_UNIT.LPN_STATE)

  if (readOnly) {
    return (
      <ReadOnlyFormValue
        title={label}
        value={!!lpnState && userFriendlyState({ lpnState }, 'lpnState')}
      />
    )
  }

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.LPN_STATE}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          required
          freeSolo={false}
          name={field.name}
          label={label}
          options={statesOptions}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          onChange={(value: ISelectOption) => field.onChange(value?.id || '')}
          isOptionEqualToValue={(option: ISelectOption) =>
            option.id === field.value
          }
        />
      )}
    />
  )
}

export default LicensePlateState
