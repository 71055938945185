const ROUTES = {
  HOME: '/',
  GATE: '/:site_id/:gate_id/gate',
  GATE_QUEUE: '/:site_id/:gate_id/gate/queue',
  GATE_TRANSACTIONS: '/:site_id/:gate_id/gate/transactions',
  GATE_DRIVERS: '/:site_id/:gate_id/yard/gate-drivers',
  YARD_CARGO_ASSET: '/:site_id/:gate_id/yard/cargo-asset',
  ISR: '/:site_id/:gate_id/isr',
  ADMIN_MISMATCHES: '/:site_id/:gate_id/administration/mismatches',
  ADMIN_VIDEO: '/:site_id/:gate_id/administration/video',
  USERS: '/enterprise/users',
  PROFILE: '/profile'
}

export default ROUTES
