import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Input, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { ReadOnlyProps } from '@/types/interfaces/ui'

const OwnerId: FC<ReadOnlyProps> = (props) => {
  const { readOnly, label = 'ID' } = props as ReadOnlyProps

  const { control, watch } = useFormContext()

  const ownerId = watch(FORM_IDS.POWER_UNIT.OWNER_ID)

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={ownerId} />
  }

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.OWNER_ID}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          uppercase
          fullWidth
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default OwnerId
