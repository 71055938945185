import { useAuth0 } from '@auth0/auth0-react'
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined'
import { Fade } from '@mui/material'
import clsx from 'clsx'
import { FC, useState } from 'react'

import { Avatar, Col, Row, Text } from '@/components/atoms'
import { CollapsedNavigationPopover } from '@/components/organisms/Navigation/components'
import { logoutUser } from '@/features/auth/store'
import { UserDetails } from '@/features/auth/types'
import { ROUTES } from '@/router'
import { useDispatch, useStore } from '@/store'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { onEnterPressed } from '@/utils/helpers'
import { useModal } from '@/hooks'
import { ContactSupportModal } from '@/features/profile'

import styles from './NavigationFooter.module.scss'

interface IProps {
  collapsed: boolean
  toggleCollapsed: () => void
}

const NavigationFooter: FC<IProps> = (props) => {
  const { collapsed, toggleCollapsed } = props

  const { logout } = useAuth0()
  const { me = {} } = useStore((state) => state.user)
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { visible, openModal, closeModal } = useModal<boolean>(false)

  const { name = '' } = me as UserDetails

  const logoutCustom = async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })

    dispatch(logoutUser())
  }

  const openSupportModal = () => {
    openModal()
    setAnchorEl(null)
  }

  return (
    <Col
      gap={20}
      items="stretch"
      className={clsx(styles.navFooter, collapsed && styles.collapsed)}
    >
      {visible && <ContactSupportModal closeModal={closeModal} />}

      <Row
        items="center"
        gap={8}
        tabIndex={0}
        clickable
        className={styles.collapseButton}
        onKeyDown={(e) => onEnterPressed(e, toggleCollapsed)}
        onClick={toggleCollapsed}
      >
        <KeyboardDoubleArrowLeftOutlinedIcon />

        <Fade in={!collapsed} timeout={500} appear={false}>
          <div>
            <Text type={TextTypes.TEXT_SM} color={Color.gray300}>
              Collapse
            </Text>
          </div>
        </Fade>
      </Row>

      <Row
        items="center"
        gap={8}
        className={styles.userInfo}
        clickable
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Avatar name={name} size="md" />

        <Fade in={!collapsed} timeout={500} appear={false}>
          <div>
            <Text
              type={TextTypes.TEXT_SM}
              color={Color.gray300}
              className={clsx(
                name.includes(' ') ? styles.wrap : styles.ellipsis
              )}
            >
              {name}
            </Text>
          </div>
        </Fade>
      </Row>

      <CollapsedNavigationPopover
        title={name}
        anchorEl={anchorEl}
        name="popover-user-profile"
        className="!tw-ml-[40px]"
        Icon={<Avatar name={name} size="md" />}
        onClose={() => setAnchorEl(null)}
        items={[
          {
            id: 'profile',
            title: 'Profile',
            link: ROUTES.PROFILE
          },
          {
            id: 'contact-support',
            title: 'Contact Support',
            action: openSupportModal
          },
          {
            id: 'logout',
            title: 'Logout',
            action: logoutCustom
          }
        ]}
      />
    </Col>
  )
}

export default NavigationFooter
