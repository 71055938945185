const port = import.meta.env.VITE_PORT
const apiUrl = import.meta.env.VITE_API_URL
const graphqlApiUrl = import.meta.env.VITE_GRAPHQL_API_URL
const isDev = import.meta.env.DEV

export default {
  port,
  isDev,
  apiUrl,
  graphqlApiUrl
}
