import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import { CircularProgress } from '@mui/material'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { Button, Col, Row, Text } from '@/components/atoms'
import { ActionsDropdown } from '@/components/organisms/Table/components'
import { CardRow, EventDwellChip } from '@/features/gate'
import {
  useGetCarrierQuery,
  useGetPresignedUrlQuery
} from '@/features/gate/api'
import { LaneDirection } from '@/features/gate/enums'
import { GateQueueEvent } from '@/features/gate/types'
import { getLaneDirectionName, prepareLpnForCard } from '@/features/gate/utils'
import { Color } from '@/styles/palette'
import { DateFormat, FontWeight, TextTypes } from '@/types/enums/ui'
import { formatDate } from '@/utils/helpers'

import styles from './QueueCard.module.scss'

interface IProps {
  item: GateQueueEvent
  type: LaneDirection
  isActionAllowed: boolean
  onSwapLane: (item: GateQueueEvent, to: LaneDirection) => void
  onCheckInOut: (item: GateQueueEvent) => void
  onDelete: () => void
}

const QueueCard: FC<IProps> = (props) => {
  const { item, type, isActionAllowed, onSwapLane, onCheckInOut, onDelete } =
    props
  const { id, metadata, created_date, organization_id: org_id, site_id } = item

  const {
    AssetChainEvent,
    power_unit_owner_id,
    power_unit_license_plate_number,
    power_unit_license_plate_state,
    power_unit_carrier_usdot,
    cargo_asset_owner_id
  } = metadata

  const cardRef = useRef<HTMLDivElement | null>(null)

  const [isInViewport, setIsInViewport] = useState<boolean>(false)
  const [metadataEnabled] = useLocalStorage('metadataEnabled', false)

  const { data: imageUrl, isFetching } = useGetPresignedUrlQuery(
    { org_id, site_id, event_id: id },
    {
      skip: !isInViewport,
      refetchOnFocus: false,
      refetchOnMountOrArgChange: false
    }
  )

  const { data: carrier } = useGetCarrierQuery(
    { org_id, us_dot: power_unit_carrier_usdot?.[0] },
    {
      skip: !isInViewport || !power_unit_carrier_usdot?.length,
      refetchOnFocus: false,
      refetchOnMountOrArgChange: false
    }
  )

  const isCheckIn = type === LaneDirection.Arriving
  const date = formatDate(created_date, DateFormat.DATE)
  const time = formatDate(created_date, DateFormat.TIME)

  const actions = useMemo(
    () => [
      {
        id: 'swap',
        label: `Move to ${getLaneDirectionName(type, true)}`,
        icon: <SwapHorizOutlinedIcon />
      },
      {
        id: 'delete',
        label: 'Delete Card',
        className: 'color-red500',
        icon: <DeleteOutlineOutlinedIcon />
      }
    ],
    []
  )

  const onItemClick = () => {
    onCheckInOut(item)
  }

  const onActionClick = (action: string) => {
    switch (action) {
      case 'swap': {
        onSwapLane(
          item,
          type === LaneDirection.Arriving
            ? LaneDirection.Departing
            : LaneDirection.Arriving
        )
        break
      }

      case 'delete': {
        onDelete()
        break
      }

      default: {
        break
      }
    }
  }

  const getAssetChainMetadata = () => {
    const assetChainId = AssetChainEvent.asset_chain.id.split('-')
    const lastPartOfId = assetChainId[assetChainId.length - 1]

    const cameraId = AssetChainEvent.camera.id.split('-')
    const lastPartOfCameraId = cameraId[cameraId.length - 1]

    return `ID: ${lastPartOfId}, Camera ID: ${lastPartOfCameraId}`
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInViewport(true)
        }
      },
      { threshold: 0.1 }
    )

    const card = cardRef.current

    if (card) {
      observer.observe(card)
    }

    return () => {
      if (card) {
        observer.unobserve(card)
      }
    }
  }, [])

  return (
    <div className={styles.card} ref={cardRef} onClick={onItemClick}>
      <EventDwellChip createdAt={created_date} />

      <div className={styles.imageWrapper}>
        {imageUrl ? (
          <img src={imageUrl} alt="Truck Card" className={styles.truckImg} />
        ) : (
          <Col items="center" justify="center" className={styles.imageLoader}>
            <CircularProgress color="secondary" />
          </Col>
        )}
      </div>

      <Col gap={12} items="stretch" className={styles.cardBody}>
        {metadataEnabled && <span>{getAssetChainMetadata()}</span>}

        <Row
          gap={10}
          items="center"
          justify="between"
          className={styles.dateWrapper}
        >
          <Text
            type={TextTypes.TEXT_XS}
            weight={FontWeight.BOLD}
            color={Color.gray700}
            className="!tw-flex tw-items-center tw-gap-4"
          >
            {date} <span className={styles.dot} /> {time}
          </Text>

          {isActionAllowed && (
            <ActionsDropdown
              table={false}
              onClick={onActionClick}
              actions={actions}
            />
          )}
        </Row>

        <CardRow
          titleOne="Power Unit LPN"
          titleTwo="Power Unit ID"
          valueOne={prepareLpnForCard(
            power_unit_license_plate_number,
            power_unit_license_plate_state
          )}
          valueTwo={power_unit_owner_id?.[0]}
        />

        <CardRow
          titleOne="Cargo Asset ID"
          titleTwo="Carrier"
          valueOne={cargo_asset_owner_id?.[0]}
          valueTwo={carrier?.data?.carrier?.name}
          loadingTwo={isFetching}
        />

        {isActionAllowed && (
          <Button
            type="secondary"
            startIcon={
              isCheckIn ? <LoginOutlinedIcon /> : <LogoutOutlinedIcon />
            }
            onClick={onItemClick}
            className="!tw-mt-auto"
          >
            {getLaneDirectionName(type)}
          </Button>
        )}
      </Col>
    </div>
  )
}

export default QueueCard
