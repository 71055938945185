import CloseIcon from '@mui/icons-material/Close'
import { Chip } from '@mui/material'
import clsx from 'clsx'
import { FC, ReactElement, KeyboardEvent, useRef, MouseEvent } from 'react'

import styles from './Chip.module.scss'
import { onEnterPressed } from '@/utils/helpers'

export type ChipVariant = 'success' | 'warning' | 'error' | 'default'

interface IProps {
  type?: ChipVariant
  icon?: ReactElement
  withBorder?: boolean
  label: string
  onClick?: () => void
  onDelete?: (event?: MouseEvent<HTMLDivElement>) => void
  size?: 'sm' | 'md' | 'lg'
  className?: string
}

const CustomChip: FC<IProps> = (props) => {
  const {
    type = 'default',
    size = 'md',
    icon,
    label,
    withBorder,
    onClick,
    onDelete,
    className
  } = props

  const withAction = !!onClick || !!onDelete

  const colorClassName = {
    success: styles.colorSuccess,
    warning: styles.colorWarning,
    error: styles.colorError,
    default: styles.colorDefault
  }

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!withAction) return

    onEnterPressed(event, () => {
      if (onClick) {
        onClick()
        return
      }

      onDelete?.()
    })
  }

  return (
    <Chip
      clickable={!!onClick}
      tabIndex={withAction ? 0 : -1}
      icon={icon}
      color={type}
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      onKeyDown={onKeyDown}
      deleteIcon={<CloseIcon className={styles.deleteIcon} />}
      classes={{
        root: clsx(
          styles.chip,
          styles[size],
          colorClassName[type],
          withAction && styles.withAction,
          withBorder && styles.border,
          className
        ),
        label: styles.label
      }}
    />
  )
}

export default CustomChip
