import { DomainTransactionTypes } from '@/features/gate/enums'
import {
  AppointmentTypes,
  CargoAssetTypes,
  EmissionType,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'
import {
  visitorFriendlyNames,
  cargoAssetTypesFriendlyNames,
  emissionTypesFriendlyNames,
  fuelTypesFriendlyNames,
  loadTypesFriendlyNames,
  powerUnitTypesFriendlyNames,
  transactionTypesFriendlyNames,
  weightClassesFriendlyNames
} from '@/utils/mappers/friendlyNames'

export const isVisitorSelectOptions = Object.values(AppointmentTypes).map(
  (type) => ({
    id: type,
    label: visitorFriendlyNames[type]
  })
)

export const classListSelectOptions = Object.values(WeightClasses).map(
  (classItem) => ({
    id: classItem,
    label: weightClassesFriendlyNames[classItem]
  })
)

export const fuelTypeSelectOptions = Object.values(FuelTypes).map(
  (fuelType) => ({
    id: fuelType,
    label: fuelTypesFriendlyNames[fuelType]
  })
)

export const emissionTypesSelectOptions = Object.values(EmissionType).map(
  (emission) => ({
    id: emission,
    label: emissionTypesFriendlyNames[emission]
  })
)

export const transactionTypesSelectOptions = Object.values(
  DomainTransactionTypes
).map((type) => ({
  id: type,
  label: transactionTypesFriendlyNames[type]
}))

export const loadStatusSelectOptions = Object.values(LoadTypes).map(
  (loadType) => ({
    id: loadType,
    label: loadTypesFriendlyNames[loadType]
  })
)

export const cargoAssetTypeSelectOptions = Object.values(CargoAssetTypes).map(
  (asset) => ({
    id: asset,
    label: cargoAssetTypesFriendlyNames[asset]
  })
)

export const powerUnitTypeSelectOptions = Object.values(PowerUnitTypes).map(
  (type) => ({
    id: type,
    label: powerUnitTypesFriendlyNames[type]
  })
)

export const mismatchSelectOptions = [
  { id: 'true', label: 'Yes' },
  { id: 'false', label: 'No' }
]
