import { FC, useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounceValue } from 'usehooks-ts'

import { Autocomplete, Input, ReadOnlyFormValue } from '@/components/atoms'
import { AUTOCOMPLETE_ITEMS_PER_PAGE, DEBOUNCE_TIME } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { useStore } from '@/store'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import { useQuery } from '@apollo/client'
import { GetDriversOnsiteQuery } from '@/features/gate/api/queries'
import { GateTransaction, StringOperator } from '@/__generated__/graphql'

interface IAutocompleteProps {
  siteId: string
  autoComplete: boolean
  selectedEventId: string | undefined
  onOptionSelect: (value: GateTransaction | undefined) => void
}

const LicensePlateNumber: FC<ReadOnlyProps | IAutocompleteProps> = (props) => {
  const { readOnly, label = 'LPN' } = props as ReadOnlyProps
  const { autoComplete, siteId, onOptionSelect, selectedEventId } =
    props as IAutocompleteProps

  const {
    control,
    watch,
    formState: { dirtyFields }
  } = useFormContext()
  const orgId = useStore((store) => store.user.org?.organization_id || '')

  const [lpnDebounced] = useDebounceValue(
    watch(FORM_IDS.POWER_UNIT.LPN),
    DEBOUNCE_TIME
  )
  const lpnState = watch(FORM_IDS.POWER_UNIT.LPN_STATE)

  const { data, loading } = useQuery(GetDriversOnsiteQuery, {
    variables: {
      input: {
        organizationID: orgId,
        siteID: siteId,
        currentPage: 1,
        itemsPerPage: AUTOCOMPLETE_ITEMS_PER_PAGE,
        filter: {
          powerUnitLicensePlateNumber: {
            operator: StringOperator.StartsWith,
            value: lpnDebounced || ''
          },

          // TODO: Remove when BE handles this
          powerUnitLicensePlateState: {
            operator: StringOperator.NotEquals,
            value: ''
          }
        }
      }
    },
    skip: !autoComplete
  })

  const powerUnitsByLpn = data?.listDriversOnSite?.drivers || []

  const getOptionLabel = useCallback(
    (option: GateTransaction) =>
      option?.metadata?.powerUnitLicensePlateNumber || '',
    []
  )

  const customOptionLabel = useCallback(
    (option: GateTransaction) =>
      `${option.metadata.powerUnitLicensePlateNumber} (${option.metadata.powerUnitLicensePlateState})`,
    []
  )

  const isOptionSelected = useCallback(
    (option: GateTransaction) => option.id === selectedEventId,
    [selectedEventId]
  )

  // On initial form load, if the LPN is already detected by TVE
  // then select the option from the list based on the LPN and LPN state
  // That should work only for autocomplete mode and before field was edited by user
  useEffect(() => {
    const isFieldTouched = dirtyFields[FORM_IDS.POWER_UNIT.LPN]

    if (!autoComplete || !lpnDebounced || isFieldTouched) return

    const selectedEvent = powerUnitsByLpn?.find?.(
      (pu) =>
        pu.metadata.powerUnitLicensePlateNumber === lpnDebounced &&
        pu.metadata.powerUnitLicensePlateState === lpnState
    )

    if (selectedEvent) {
      onOptionSelect(selectedEvent as GateTransaction)
    }
  }, [powerUnitsByLpn])

  if (autoComplete) {
    return (
      <Controller
        name={FORM_IDS.POWER_UNIT.LPN}
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            required
            label={label}
            name={field.name}
            inputValue={field.value}
            options={powerUnitsByLpn || []}
            loading={loading}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            onInputChange={(e, newValue) => field.onChange(newValue)}
            getOptionLabel={getOptionLabel}
            customOptionLabel={customOptionLabel}
            isOptionEqualToValue={isOptionSelected}
            onChange={onOptionSelect}
            inputProps={{
              uppercase: true
            }}
          />
        )}
      />
    )
  }

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={lpnDebounced} />
  }

  return (
    <Controller
      control={control}
      name={FORM_IDS.POWER_UNIT.LPN}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          required
          uppercase
          fullWidth
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default LicensePlateNumber
