import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { FC } from 'react'

import { Button, Col, Row, Text } from '@/components/atoms'
import { useNavigator } from '@/router'
import { Color } from '@/styles/palette'
import { ErrorType, TextAlign, TextTypes } from '@/types/enums/ui'

import styles from './ErrorTemplate.module.scss'
import { ERRORS } from '@/constants'

interface IProps {
  type?: ErrorType
  title?: string
  description?: string
}

const errorsMapper = {
  [ErrorType.Generic]: ERRORS.GENERIC,
  [ErrorType.NotFound]: ERRORS.PAGE_NOT_FOUND,
  [ErrorType.Unavailable]: ERRORS.SERVICE_UNAVAILABLE
}

const ErrorTemplate: FC<IProps> = (props) => {
  const { type = ErrorType.Generic, title, description } = props

  const navigate = useNavigator()

  const errorDetails = errorsMapper[type]

  const onPageRefresh = () => {
    window.location.reload()
  }

  return (
    <Row items="center" justify="center" className={styles.errorWrapper}>
      <Col gap={32} items="center" className={styles.errorContainer}>
        {type === ErrorType.Generic ? (
          <ErrorOutlineIcon />
        ) : (
          <span className={styles.errorCodePlaceholder}>{type}</span>
        )}

        <Col items="center" gap={16}>
          <Text
            type={TextTypes.DISPLAY_SM}
            color={Color.gray800}
            align={TextAlign.CENTER}
          >
            {title || errorDetails.title}
          </Text>

          <Text
            type={TextTypes.TEXT_XL}
            color={Color.gray800}
            align={TextAlign.CENTER}
          >
            {description || errorDetails.description}
          </Text>
        </Col>

        {type === ErrorType.NotFound ? (
          <Row items="stretch" gap={16}>
            <Button type="primary" onClick={navigate.toHome}>
              Go to Homepage
            </Button>

            <Button type="outlined">Contact Us</Button>
          </Row>
        ) : (
          <Button type="primary" onClick={onPageRefresh}>
            Refresh Page
          </Button>
        )}
      </Col>
    </Row>
  )
}

export default ErrorTemplate
