import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Collapse } from '@mui/material'
import clsx from 'clsx'
import { FC, MouseEvent, PropsWithChildren, useState } from 'react'

import { Button, Col, Row, Text } from '@/components/atoms'
import { ToggleDetailsButton } from '@/components/molecules'
import { COLLAPSE_ANIMATION_DURATION } from '@/constants'
import { DeleteFormSectionModal } from '@/features/forms/components'
import { useModal } from '@/hooks'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './FormSection.module.scss'

interface IProps extends PropsWithChildren {
  title: string
  collapsed?: boolean
  collapsible?: boolean
  id?: string
  onRemove?: () => void
}

const FormSection: FC<IProps> = (props) => {
  const {
    id,
    title,
    children,
    collapsed = false,
    collapsible = true,
    onRemove
  } = props

  const [open, setOpen] = useState<boolean>(!collapsed)
  const { visible, closeModal, openModal } = useModal<boolean>(false)

  const toggleOpen = () => collapsible && setOpen(!open)

  const onDeleteClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    openModal()
  }

  return (
    <>
      {visible && (
        <DeleteFormSectionModal
          title={title}
          onDelete={onRemove!}
          closeModal={closeModal}
        />
      )}

      <Col id={id} items="stretch" className={styles.formSection}>
        <Row
          gap={10}
          items="center"
          justify="between"
          onClick={toggleOpen}
          className={clsx({
            [styles.collapsibleHeader]: collapsible
          })}
        >
          <Text
            type={TextTypes.TEXT_MD}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray700}
          >
            {title}
          </Text>

          <Row items="center" gap={16}>
            {!!onRemove && (
              <Button
                type="dangerText"
                startIcon={<DeleteOutlineIcon />}
                onClick={onDeleteClick}
              >
                Delete
              </Button>
            )}

            {collapsible && <ToggleDetailsButton arrow showDetails={open} />}
          </Row>
        </Row>

        <Collapse in={open} timeout={COLLAPSE_ANIMATION_DURATION}>
          <div className={styles.collapsedContent}>{children}</div>
        </Collapse>
      </Col>
    </>
  )
}

export default FormSection
