import { Col, Loader, Row } from '@/components/atoms'
import { BaseEmptyState } from '@/components/organisms'
import { BREAKPOINTS } from '@/constants'
import { Queues } from '@/features/gate'
import { useFetchLanesQuery } from '@/features/gate/api'
import { useStore } from '@/store'
import { useWindowSize } from 'usehooks-ts'

const GateQueueTemplate = () => {
  const { org, selectedPortal, selectedGate } = useStore(
    (store) => store.user || {}
  )

  const org_id = org?.organization_id || ''
  const site_id = selectedPortal?.id || ''

  const gate_id = selectedGate?.id || ''

  const { width } = useWindowSize()
  const isSmallView = width <= BREAKPOINTS.SM

  const { data: lanesResponse, isFetching: loading } = useFetchLanesQuery(
    { org_id, site_id, gate_id },
    { skip: !org_id || !site_id || !gate_id }
  )

  const lanes = !gate_id ? [] : lanesResponse?.data?.lanes || []
  const gatesOrLanes = !gate_id ? 'gates' : 'lanes'

  return (
    <div
      style={{
        display: 'flex',
        maxHeight: `calc(100vh - ${isSmallView ? 0 : 100}px)`,
        width: '100%',
        height: '100%'
      }}
    >
      {loading || !lanes.length ? (
        <Row items="center" justify="center" gap={8} className="tw-w-full">
          <Col items="center" justify="center" gap={8} className="tw-h-full">
            {loading ? (
              <Loader />
            ) : (
              <BaseEmptyState
                primaryText={`There are no ${gatesOrLanes}`}
                descriptionText={`The selected site does not contain any ${gatesOrLanes}.`}
              />
            )}
          </Col>
        </Row>
      ) : (
        <Queues lanes={lanes} gateId={gate_id} />
      )}
    </div>
  )
}

export default GateQueueTemplate
