import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounceValue } from 'usehooks-ts'

import { Autocomplete, ReadOnlyFormValue } from '@/components/atoms'
import { DEBOUNCE_TIME } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { useGetCarriersQuery } from '@/features/gate/api'
import { Carrier } from '@/features/gate/types'
import { useStore } from '@/store'
import { ReadOnlyProps } from '@/types/interfaces/ui'

interface IProps {}

const AssetCarrier: FC<IProps | ReadOnlyProps> = (props) => {
  const { readOnly, label = 'Carrier' } = props as ReadOnlyProps

  const orgId = useStore((store) => store.user.org?.organization_id || '')

  const { control, watch } = useFormContext()

  const [carrierDebounced] = useDebounceValue(
    watch(FORM_IDS.CARGO_ASSET.CARRIER),
    DEBOUNCE_TIME
  )

  const { data: carriersResponse, isFetching: carriersLoading } =
    useGetCarriersQuery(
      {
        org_id: orgId,
        partial_name: carrierDebounced || ''
      },
      { skip: readOnly }
    )

  const carriersList = carriersResponse?.data?.carriers || []

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={carrierDebounced} />
  }

  return (
    <Controller
      name={FORM_IDS.CARGO_ASSET.CARRIER}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          label={label}
          name={field.name}
          inputValue={field.value}
          options={carriersList || []}
          loading={carriersLoading}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          onInputChange={(e, newValue) => field.onChange(newValue)}
          getOptionLabel={(option: Carrier) => option?.name || ''}
          customOptionLabel={(option: Carrier) =>
            `${option.name} (${option.us_dot_number})`
          }
          isOptionEqualToValue={(option: Carrier) =>
            option.name === field.value
          }
          onChange={(value: Carrier) => {
            field.onChange(value?.name || '')
          }}
          inputProps={{
            uppercase: true
          }}
        />
      )}
    />
  )
}

export default AssetCarrier
