export const ERRORS = {
  NO_GATES: {
    title: 'There are no gates',
    description: 'The selected site does not contain any gates.'
  },

  NO_LANES: {
    title: 'There are no lanes',
    description: 'The selected gate does not contain any lanes.'
  },

  NO_PORTALS: {
    title: 'No portals available',
    description:
      "You don't have permission to access any portal. Please contact your organization's administrator for assistance."
  },

  NO_PERMISSIONS: {
    title: 'No permissions have been granted',
    description:
      "You have access to the selected portal, but no permissions have been granted. Please contact your organization's administrator for assistance."
  },

  GENERIC: {
    title: 'Something went wrong',
    description:
      'We encountered an unexpected error, but don’t worry - we’re already working on fixing it. You can refresh the page or try again later.'
  },

  PAGE_NOT_FOUND: {
    title: 'Page Not Found',
    description: 'Sorry about that! We can’t find the page you’re looking for.'
  },

  SERVICE_UNAVAILABLE: {
    title: 'Service Unavailable',
    description:
      'Sorry, we’re currently under maintenance. Please refresh the page or try again later.'
  }
}
