import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { createTheme, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { FC, useEffect, useMemo, useState } from 'react'

import { Loader } from '@/components/atoms'
import { MobileNavigationContextProvider } from '@/components/contexts'
import { RootRouter, useNavigator } from '@/router'
import { useStore } from '@/store'
import { apolloClient } from '@/api'
import { ApolloProvider } from '@apollo/client'

const theme = createTheme({
  typography: {
    fontFamily: ['Suisse', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
      ','
    )
  }
})

const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN
const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID
const audience = import.meta.env.VITE_AUTH0_AUDIENCE
const defaultOrg = import.meta.env.VITE_DEFAULT_ORG_ID

const App: FC = () => {
  const navigate = useNavigator()
  const [organization, setOrganization] = useState<string | undefined>()

  const { authToken = '' } = useStore((store) => store.user)

  const client = useMemo(() => apolloClient(authToken), [authToken])

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate.to((appState && appState.returnTo) || window.location.pathname)
  }

  useEffect(() => {
    fetch(window.location.href).then((response) => {
      setOrganization(response.headers.get('Ti-Organization-Id') || defaultOrg)
    })
  }, [])

  if (!organization) {
    return <Loader fullScreen />
  }

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
        organization
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileNavigationContextProvider>
            <ApolloProvider client={client}>
              <RootRouter />
            </ApolloProvider>
          </MobileNavigationContextProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Auth0Provider>
  )
}

export default App
