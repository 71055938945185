import { InputAdornment } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'
import clsx from 'clsx'
import dayjs from 'dayjs'
import React, { forwardRef, ForwardRefRenderFunction, useState } from 'react'

import { TimeInputProps } from '@/types/interfaces/ui'

import classes from '../classes'
import { TimeUnit } from '@/types/enums/ui'

const TimeInput: ForwardRefRenderFunction<HTMLInputElement, TimeInputProps> = (
  props,
  ref
) => {
  const {
    icon,
    fullWidth,
    required,
    placeholder,
    helperText,
    hideDayTime,
    ...restProps
  } = props

  const {
    inputClasses,
    inputLabelClasses,
    helperTextClasses,
    iconClasses,
    rootClasses
  } = classes

  const [open, setOpen] = useState<boolean>(false)

  const additionalProps = hideDayTime
    ? {
        ampm: false,
        minTime: dayjs().set(TimeUnit.Hour, 1).set(TimeUnit.Minute, 0)
      }
    : {}

  return (
    <TimePicker
      {...restProps}
      {...additionalProps}
      ref={ref}
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        textField: {
          error: false,
          required,
          placeholder,
          helperText,
          variant: 'standard',
          onClick: (e) => {
            e.stopPropagation()
            setOpen(() => !open)
          },
          classes: {
            root: clsx(rootClasses.root, fullWidth ? 'tw-w-full' : 'tw-w-115')
          },
          InputProps: {
            required,
            fullWidth,
            endAdornment: null,
            startAdornment: icon && (
              <InputAdornment classes={iconClasses} position="start">
                {icon}
              </InputAdornment>
            ),
            classes: inputClasses
          },
          InputLabelProps: {
            required,
            classes: inputLabelClasses
          },
          FormHelperTextProps: {
            classes: helperTextClasses
          }
        }
      }}
    />
  )
}

export default forwardRef(TimeInput)
