import { FC, useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import { Captions, Fullscreen, Zoom } from 'yet-another-react-lightbox/plugins'

import styles from './ImageWithPreview.module.scss'

interface IProps {
  title?: string
  imageUrl: string
  alt?: string
  className?: string
}
const ImageWithPreview: FC<IProps> = (props) => {
  const { imageUrl, title, alt, className } = props

  const [showPreview, setShowPreview] = useState<boolean>(false)

  return (
    <>
      <Lightbox
        plugins={[Captions, Fullscreen, Zoom]}
        open={showPreview}
        close={() => setShowPreview(false)}
        slides={[{ src: imageUrl, title }]}
        className={styles.lightboxWrapper}
        zoom={{ maxZoomPixelRatio: 3 }}
        styles={{
          navigationNext: { display: 'none' },
          navigationPrev: { display: 'none' }
        }}
      />

      <img
        src={imageUrl}
        alt={alt}
        className={className}
        onClick={() => setShowPreview(true)}
      />
    </>
  )
}

export default ImageWithPreview
