import { FC, useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounceValue } from 'usehooks-ts'

import { Autocomplete, Input, ReadOnlyFormValue } from '@/components/atoms'
import { AUTOCOMPLETE_ITEMS_PER_PAGE, DEBOUNCE_TIME } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { useStore } from '@/store'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import { useQuery } from '@apollo/client'
import { GetCargoAssetsOnsiteQuery } from '@/features/gate/api/queries'
import { GateTransaction, StringOperator } from '@/__generated__/graphql'

interface IProps extends ReadOnlyProps {
  required?: boolean
}

interface IAutocompleteProps {
  siteId: string
  autoComplete: boolean
  selectedEventId: string | undefined
  onOptionSelect: (value: GateTransaction | undefined) => void
}

const OwnerId: FC<IProps | IAutocompleteProps> = (props) => {
  const { readOnly, label = 'Asset ID', required = true } = props as IProps

  const { autoComplete, siteId, onOptionSelect, selectedEventId } =
    props as IAutocompleteProps

  const {
    control,
    watch,
    formState: { dirtyFields }
  } = useFormContext()
  const orgId = useStore((store) => store.user.org?.organization_id || '')

  const [assetIdDebounced] = useDebounceValue(
    watch(FORM_IDS.CARGO_ASSET.OWNER_ID),
    DEBOUNCE_TIME
  )

  const { data, loading } = useQuery(GetCargoAssetsOnsiteQuery, {
    variables: {
      input: {
        organizationID: orgId,
        siteID: siteId,
        currentPage: 1,
        itemsPerPage: AUTOCOMPLETE_ITEMS_PER_PAGE,
        filter: {
          cargoAssetOwnerId: {
            operator: StringOperator.StartsWith,
            value: assetIdDebounced || ''
          }
        }
      }
    },
    skip: !autoComplete
  })

  const assetsById = data?.listCargoAssetsOnSite?.assets || []

  const getOptionLabel = useCallback(
    (option: GateTransaction) => option?.metadata?.cargoAssetOwnerId || '',
    []
  )

  const isOptionSelected = useCallback(
    (option: GateTransaction) => option.id === selectedEventId,
    [selectedEventId]
  )

  // On initial form load, if the Owner ID is already detected by TVE
  // then select the option from the list based on its value
  // That should work only for autocomplete mode and before field was edited by user
  useEffect(() => {
    const isFieldTouched = dirtyFields[FORM_IDS.CARGO_ASSET.OWNER_ID]

    if (!autoComplete || !assetIdDebounced || isFieldTouched) return

    const selectedEvent = assetsById?.find?.(
      (asset) => asset.metadata.cargoAssetOwnerId === assetIdDebounced
    )

    if (selectedEvent) {
      onOptionSelect(selectedEvent as GateTransaction)
    }
  }, [assetsById])

  if (autoComplete) {
    return (
      <Controller
        name={FORM_IDS.CARGO_ASSET.OWNER_ID}
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            required={required}
            label={label}
            name={field.name}
            inputValue={field.value}
            options={assetsById || []}
            loading={loading}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            onInputChange={(e, newValue) => {
              field.onChange(newValue)
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionSelected}
            onChange={onOptionSelect}
            inputProps={{
              uppercase: true
            }}
          />
        )}
      />
    )
  }

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={assetIdDebounced} />
  }

  return (
    <Controller
      control={control}
      name={FORM_IDS.CARGO_ASSET.OWNER_ID}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          uppercase
          fullWidth
          label={label}
          required={required}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default OwnerId
