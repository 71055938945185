import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import {
  Collapse,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import clsx from 'clsx'
import React, { FC, useState } from 'react'

import { Col, Text } from '@/components/atoms'
import { PortalDropdownItem } from '@/components/organisms/Navigation/components'
import { selectPortal } from '@/features/auth/store'
import { useNavigator } from '@/router'
import { useDispatch, useStore } from '@/store'
import { Color } from '@/styles/palette'
import { PortalTypes } from '@/types/enums/global'
import { TextTypes } from '@/types/enums/ui'

import styles from './PortalsDropdownListMobile.module.scss'

const PortalsDropdownListMobile: FC = () => {
  const { portals, selectedPortal } = useStore((store) => store.user)

  const dispatch = useDispatch()
  const navigate = useNavigator()

  const [open, setOpen] = useState<boolean>(false)

  const isEnterpriseEnabled = portals.some(
    (portal) => portal.id === PortalTypes.Enterprise
  )
  const hasSitePortals = portals.some(
    (portal) => portal.type === PortalTypes.Site
  )

  const toggleOpen = () => {
    setOpen(!open)
  }

  const onItemClick = (newValue: string) => {
    dispatch(
      selectPortal({
        portalId: newValue,
        redirect: navigate.toHome
      })
    )
    setOpen(false)
  }

  const onChange = (e: SelectChangeEvent<string>) => {
    onItemClick(e.target.value)
  }

  if (!selectedPortal?.id) return null

  const Enterprise = isEnterpriseEnabled && (
    <MenuItem
      tabIndex={0}
      value={PortalTypes.Enterprise}
      onClick={() => onItemClick(PortalTypes.Enterprise)}
      className={clsx(
        selectedPortal.id === PortalTypes.Enterprise && styles.hidden
      )}
    >
      <PortalDropdownItem
        type={PortalTypes.Enterprise}
        text="Enterprise Portal"
      />
    </MenuItem>
  )

  const SubHeader = (
    <ListSubheader className={styles.subHeader}>
      <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
        Site Portal
      </Text>
    </ListSubheader>
  )

  const SiteItems = portals.map((portal) =>
    portal.type === PortalTypes.Site ? (
      <MenuItem
        key={portal.id}
        tabIndex={0}
        value={portal.id}
        onClick={() => onItemClick(portal.id)}
        className={clsx(selectedPortal.id === portal.id && styles.hidden)}
      >
        <PortalDropdownItem type={PortalTypes.Site} text={portal.name} />
      </MenuItem>
    ) : undefined
  )

  return (
    <div className={styles.portals}>
      <Select
        fullWidth
        value={selectedPortal.id}
        onOpen={toggleOpen}
        onClose={toggleOpen}
        IconComponent={KeyboardArrowDownOutlinedIcon}
        onChange={onChange}
        inputProps={{ 'aria-label': 'Portal' }}
        className={clsx(styles.wrapper, open && styles.opened)}
        classes={{
          select: styles.select,
          icon: clsx(styles.icon, portals.length === 1 && 'tw-opacity-0')
        }}
        MenuProps={{
          open: false,
          classes: {
            paper: styles.dropdown
          }
        }}
      >
        {Enterprise}
        {SiteItems}
      </Select>

      <Collapse in={open} unmountOnExit timeout={500}>
        <Col className={styles.mobileList}>
          {Enterprise}

          {hasSitePortals && (
            <>
              {SubHeader}
              {SiteItems}
            </>
          )}
        </Col>
      </Collapse>
    </div>
  )
}

export default PortalsDropdownListMobile
