import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined'

import {
  EnterprisePortalPermissions,
  SitePortalPermissions
} from '@/features/auth/types'
import ROUTES from '@/router/routes'
import { PortalTypes } from '@/types/enums/global'
import { INavigationItem } from '@/types/interfaces/ui'

export const navigationItems: INavigationItem[] = [
  {
    id: 'gate',
    title: 'Gate',
    icon: <LocalShippingOutlinedIcon />,
    visible: (portalType: PortalTypes, permissions: SitePortalPermissions) =>
      portalType === PortalTypes.Site &&
      (permissions.gate_queue || permissions.gate_transaction),
    link: ROUTES.GATE,
    items: [
      {
        id: 'queue',
        title: 'Queue',
        link: ROUTES.GATE_QUEUE,
        visible: (permissions: SitePortalPermissions) => permissions.gate_queue
      },
      {
        id: 'transactions',
        title: 'Transactions',
        link: ROUTES.GATE_TRANSACTIONS,
        visible: (permissions: SitePortalPermissions) =>
          permissions.gate_transaction
      }
    ],
    disableAccordion: true
  },
  {
    id: 'yard',
    title: 'Yard',
    icon: <ContentPasteOutlinedIcon />,
    visible: (portalType: PortalTypes, permissions: SitePortalPermissions) => {
      const hasAccessToCargoAsset = permissions.onsite_cargo_asset
      const hasAccessToDrivers = permissions.onsite_driver

      const mainItemVisible = hasAccessToCargoAsset || hasAccessToDrivers

      return portalType === PortalTypes.Site && mainItemVisible
    },
    items: [
      {
        id: 'cargo-asset',
        title: 'Cargo Asset',
        link: ROUTES.YARD_CARGO_ASSET,
        visible: (permissions: SitePortalPermissions) =>
          permissions.onsite_cargo_asset
      },
      {
        id: 'drivers',
        title: 'Drivers',
        link: ROUTES.GATE_DRIVERS,
        visible: (permissions: SitePortalPermissions) =>
          permissions.onsite_driver
      }
    ]
  },
  {
    id: 'isr',
    title: 'ISR',
    link: ROUTES.ISR,
    icon: <WbSunnyOutlinedIcon />,
    visible: (portalType: PortalTypes, permissions: SitePortalPermissions) =>
      portalType === PortalTypes.Site && permissions.isr
  },
  {
    id: 'users',
    title: 'Users',
    link: ROUTES.USERS,
    icon: <GroupOutlinedIcon />,
    visible: (
      portalType: PortalTypes,
      permissions: EnterprisePortalPermissions
    ) => portalType === PortalTypes.Enterprise && permissions.users
  }
]
