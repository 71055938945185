import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { FC } from 'react'

import { Button } from '@/components/atoms'
import { CommonButtonProps } from '@/types/interfaces/ui'

import styles from './ToggleDetailsButton.module.scss'

interface IProps extends Omit<CommonButtonProps, 'children'> {
  arrow?: boolean
  showDetails: boolean
}

const ToggleDetailsButton: FC<IProps> = (props) => {
  const { arrow, showDetails, onClick, ...rest } = props

  const Icon = showDetails ? (
    <KeyboardArrowUpOutlinedIcon />
  ) : (
    <KeyboardArrowDownOutlinedIcon />
  )

  return arrow ? (
    <div className={styles.iconWrapper}>{Icon}</div>
  ) : (
    <Button
      {...rest}
      type="text"
      endIcon={Icon}
      onClick={onClick}
      className="tw-self-start !tw-px-0"
    >
      {showDetails ? 'Hide Details' : 'Show Details'}
    </Button>
  )
}

export default ToggleDetailsButton
