import clsx from 'clsx'
import React, {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  ReactNode
} from 'react'

import { IFlexProps } from '@/types/interfaces/ui'

import styles from './Row.module.scss'

interface IRowProps extends Omit<IFlexProps, 'direction'> {
  id?: string
  className?: string
  tabIndex?: number
  children?: ReactNode
}

const Row: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<IRowProps>
> = (
  {
    id,
    inline,
    onClick,
    tabIndex,
    children,
    clickable,
    className,
    onKeyDown,
    gap = 0,
    reverse = false,
    items = 'start',
    justify = 'start'
  }: IRowProps,
  ref
) => (
  <div
    id={id}
    ref={ref}
    tabIndex={tabIndex}
    onKeyDown={onKeyDown}
    style={{ '--gap': `${gap}px` } as CSSProperties}
    className={clsx(
      styles.row,
      inline && styles.inline,
      styles[`justify-${justify}`],
      styles[`align-${items}`],
      reverse && styles.reverse,
      clickable && 'tw-cursor-pointer',
      className
    )}
    onClick={onClick}
  >
    {children}
  </div>
)

export default forwardRef(Row)
