import clsx from 'clsx'
import { FC, useEffect, useMemo, useState } from 'react'
import { useLocalStorage, useWindowSize } from 'usehooks-ts'

import { useMobileNavigationContext } from '@/components/contexts'
import { BREAKPOINTS } from '@/constants'
import { useStore } from '@/store'
import { navigationItems } from '@/utils/data/navigationItems'

import {
  NavigationFooter,
  NavigationHeader,
  NavigationItem,
  PortalsDropdownList,
  PortalsDropdownListMobile
} from './components'
import styles from './Navigation.module.scss'
import { withParams } from '@/api'

const Navigation: FC = () => {
  const { mobileNavigationOpened, toggleMobileNavigation } =
    useMobileNavigationContext()

  const { width = 0 } = useWindowSize()
  const { selectedPortal, selectedGate } = useStore((store) => store.user)

  const [expandedItem, setExpandedItem] = useState<string | undefined>()
  const [collapsed, setCollapsed] = useLocalStorage('sidebarCollapsed', false)

  const isSmallView = width <= BREAKPOINTS.MD

  const itemsToRender = useMemo(() => {
    if (!selectedPortal || !navigationItems) return []

    const { id: gate_id = '' } = selectedGate || {}
    const { id: site_id, type, permissions } = selectedPortal

    return (
      navigationItems
        // Remove high level items that are not available
        .filter((item) => item.visible(type, permissions))
        .map((item) => {
          const { link, items } = item

          if (link)
            return {
              ...item,
              link: withParams(link, {
                site_id,
                gate_id
              })
            }

          // Remove sub-items that are not available
          const availableSubItems = items
            ?.filter((subItem) => subItem.visible(permissions))
            .map((subItem) => ({
              ...subItem,
              link: withParams(subItem.link, {
                site_id,
                gate_id
              })
            }))

          return { ...item, items: availableSubItems || undefined }
        })
    )
  }, [selectedPortal, selectedGate])

  const handleExpandItem = (id: string) => {
    setExpandedItem(id === expandedItem ? undefined : id)
  }

  const toggleCollapsed = () => setCollapsed((prev) => !prev)

  useEffect(() => {
    if (isSmallView && collapsed && mobileNavigationOpened) {
      setCollapsed(false)
    }
  }, [width, mobileNavigationOpened])

  const PortalPicker = isSmallView ? (
    <PortalsDropdownListMobile />
  ) : (
    <PortalsDropdownList collapsed={collapsed} />
  )

  return (
    <>
      {mobileNavigationOpened && (
        <div
          onClick={() => toggleMobileNavigation()}
          className={styles.blackBackground}
        />
      )}

      <nav
        className={clsx(
          styles.nav,
          collapsed && styles.collapsed,
          mobileNavigationOpened && styles.mobileNavigationOpened
        )}
      >
        <NavigationHeader collapsed={collapsed} />

        {!!selectedPortal?.id && PortalPicker}

        <div className={styles.navItems}>
          {itemsToRender.map((item) => (
            <NavigationItem
              key={item.id}
              item={item}
              onExpand={handleExpandItem}
              sidebarCollapsed={collapsed}
              isExpanded={expandedItem === item.id}
            />
          ))}
        </div>

        <NavigationFooter
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
      </nav>
    </>
  )
}

export default Navigation
