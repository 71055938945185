import { CargoAssetTypes } from '@/types/enums/transactionDetails'
import { AssetChain } from '@/features/gate/types'
import { SEVEN_DIGITS_AT_THE_END } from '@/constants/regex'

const getCargoAssetTypeFromAssetChain = (
  assetChain: AssetChain
): CargoAssetTypes | undefined => {
  const { assets } = assetChain
  const { trailer, container } = assets
  const { container_id = [] } = container || {}

  const withoutCargoAsset = !trailer && !container
  const containerWithoutId = !!container && !container_id.length

  if (withoutCargoAsset || containerWithoutId) return undefined

  if (trailer) return CargoAssetTypes.Trailer

  // Only ocean containers contains 7 digits at the end of their asset id
  const isOceanContainer = container_id.some((item) =>
    SEVEN_DIGITS_AT_THE_END.test(item)
  )

  return isOceanContainer
    ? CargoAssetTypes.OceanContainer
    : CargoAssetTypes.IntermodalContainer
}

export default getCargoAssetTypeFromAssetChain
