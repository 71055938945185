import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Button, Col, Row, Text } from '@/components/atoms'
import { WithHeaderTemplate } from '@/components/templates'
import { selectPortal } from '@/features/auth/store'
import { UserPortalsListItem } from '@/features/auth/types'
import { useNavigator } from '@/router'
import { useDispatch, useStore } from '@/store'
import { Color } from '@/styles/palette'
import { PortalTypes } from '@/types/enums/global'
import { FontWeight, TextAlign, TextTypes } from '@/types/enums/ui'
import { INavigationItem } from '@/types/interfaces/ui'
import { navigationItems } from '@/utils/data/navigationItems'
import { getInitials } from '@/utils/helpers'

import { Modal } from '@/components/organisms'
import { Launch } from '@mui/icons-material'
import styles from './EnterpriseLanding.module.scss'

import useSnackbar from '@/features/snackbars-queue/hooks/useSnackbar'
import { useEnableSsoMutation } from '@/features/user-management/api'
import { useSsoEnabled } from '../../hooks/useSsoEnabled'
import EnableSsoTile from './EnableSsoTile'

const portalAvatarsColors = [
  'background-color-yellow400 color-gray800',
  'background-color-blue300 color-gray0',
  'background-color-green500 color-gray0',
  'background-color-purple400 color-gray0'
]

const EnterpriseLanding = () => {
  const { showErrorSnackbar } = useSnackbar()
  const { portals, selectedPortal, me, org } = useStore((store) => store.user)

  const { first_name = '' } = me || {}
  const { permissions = {} } = selectedPortal || {}

  const [enableSso, { isLoading: generatingTicket }] = useEnableSsoMutation()

  const navigate = useNavigator()
  const dispatch = useDispatch()

  const [enableSsoModalOpen, setEnableSsoModalOpen] = useState<boolean>(false)

  const { ssoEnabled } = useSsoEnabled()

  const sitePortals = useMemo(
    () => portals.filter((portal) => portal.type !== PortalTypes.Enterprise),
    [portals]
  )

  const adminTools: INavigationItem[] = useMemo(
    () =>
      Object.entries(permissions)
        .filter(([, enabled]) => enabled)
        .map(([pageId]) => navigationItems.find((item) => item.id === pageId)!),
    [permissions]
  )

  const onEnableSsoClicked = async () => {
    if (!org) return
    const orgId = org.organization_id

    const response = await enableSso({
      orgId,
      connection_name: 'okta-sso'
    })

    const ticketLink = response?.data?.data?.ticket || ''

    if (!ticketLink) {
      showErrorSnackbar('Failed to generate setup link')
      return
    }

    window.open(ticketLink, '_blank')
    setEnableSsoModalOpen(false)
  }

  const onSiteClick = async (sitePortal: UserPortalsListItem) => {
    await dispatch(
      selectPortal({
        portalId: sitePortal.id,
        redirect: navigate.toHome
      })
    )
  }

  return (
    <WithHeaderTemplate
      title={`Hi ${first_name}!`}
      className={styles.landingPageWrapper}
    >
      {enableSsoModalOpen && (
        <Modal
          title={'Enable SSO'}
          closeModal={() => setEnableSsoModalOpen(false)}
          cancelButtonText="Cancel"
          footer={
            <Row items="stretch" gap={8}>
              <Button
                onClick={onEnableSsoClicked}
                type="primary"
                startIcon={<Launch />}
                disabled={generatingTicket}
              >
                {generatingTicket ? 'Generating...' : 'Continue'}
              </Button>
            </Row>
          }
        >
          <Col gap={8}>
            <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
              Continue to generate a custom link that will redirect you to
              complete your SSO complete setup.
            </Text>
            <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
              This link will expire in <b>5 hours</b>. If the setup is not
              finished within that time, you will need to return here to restart
              the process.
            </Text>
          </Col>
        </Modal>
      )}

      <Col gap={40} className={styles.landingPageContent}>
        <Text type={TextTypes.DISPLAY_XS} color={Color.gray700}>
          Where would you like to go?
        </Text>

        <Col gap={16}>
          <Text
            type={TextTypes.TEXT_SM}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray600}
          >
            Admin Tools
          </Text>

          <section className={styles.listItems}>
            {adminTools.map((item) => (
              <Link
                to={item.link!}
                key={item.id}
                className={styles.adminToolLink}
              >
                <Col items="center" justify="end" className={styles.card}>
                  {item.icon}

                  <Text
                    type={TextTypes.TEXT_SM}
                    weight={FontWeight.SEMIBOLD}
                    align={TextAlign.CENTER}
                    color={Color.gray700}
                    className={styles.cardTitle}
                  >
                    {item.title}
                  </Text>
                </Col>
              </Link>
            ))}

            <EnableSsoTile
              ssoEnabledState={ssoEnabled}
              openEnableSsoModal={() => setEnableSsoModalOpen(true)}
            />
          </section>
        </Col>

        {sitePortals.length > 0 && (
          <Col gap={16}>
            <Text
              type={TextTypes.TEXT_SM}
              weight={FontWeight.SEMIBOLD}
              color={Color.gray600}
            >
              Site Portals
            </Text>

            <section className={styles.listItems}>
              {sitePortals.map((portal, index) => (
                <Col
                  key={portal.id}
                  items="center"
                  justify="end"
                  className={styles.card}
                  onClick={() => onSiteClick(portal)}
                >
                  <div
                    className={clsx(
                      styles.portalAvatar,
                      portalAvatarsColors[index % portalAvatarsColors.length]
                    )}
                  >
                    <Text type={TextTypes.TEXT_SM} weight={FontWeight.SEMIBOLD}>
                      {getInitials(portal.name)}
                    </Text>
                  </div>

                  <Text
                    type={TextTypes.TEXT_SM}
                    weight={FontWeight.SEMIBOLD}
                    align={TextAlign.CENTER}
                    color={Color.gray700}
                    className={styles.cardTitle}
                  >
                    {portal.name}
                  </Text>
                </Col>
              ))}
            </section>
          </Col>
        )}
      </Col>
    </WithHeaderTemplate>
  )
}

export default EnterpriseLanding
