export const PAGINATION_ITEMS_PER_PAGE = 20
export const AUTOCOMPLETE_ITEMS_PER_PAGE = 10

export const OLDEST_AVAILABLE_DATE = '1970-01-01'

export const DEBOUNCE_TIME = 800

export const MIN_VIN_LENGTH = 10
export const MIN_LPN_LENGTH = 3
export const MIN_SEARCH_QUERY_LENGTH = 3
