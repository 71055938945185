import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { Popover } from '@mui/material'
import clsx from 'clsx'
import React, { FC } from 'react'

import { Avatar, Col, Row, Text } from '@/components/atoms'
import { useStore } from '@/store'
import { Color } from '@/styles/palette'
import { PortalTypes } from '@/types/enums/global'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { onEnterPressed } from '@/utils/helpers'

import styles from './CollapsedPortalPicker.module.scss'

interface IProps {
  anchorEl: HTMLElement | null
  onClose: () => void
  onItemClick: (id: string) => void
}

const CollapsedPortalPicker: FC<IProps> = (props) => {
  const { anchorEl, onClose, onItemClick } = props

  const { selectedPortal, portals } = useStore((store) => store.user)

  const open = Boolean(anchorEl)

  const isEnterpriseSelected = selectedPortal?.type === PortalTypes.Enterprise
  const isEnterpriseEnabled = portals.some(
    (portal) => portal.id === PortalTypes.Enterprise
  )

  return (
    <Popover
      id="portal-picker"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      classes={{ paper: styles.navigationItemPopover }}
    >
      {isEnterpriseEnabled && (
        <Row
          clickable
          items="center"
          gap={8}
          tabIndex={0}
          key={PortalTypes.Enterprise}
          className={clsx(
            styles.subItemLink,
            styles.enterprise,
            isEnterpriseSelected && styles.active
          )}
          onKeyDown={(e) =>
            onEnterPressed(e, () => onItemClick(PortalTypes.Enterprise))
          }
          onClick={() => onItemClick(PortalTypes.Enterprise)}
        >
          <Avatar
            size="md"
            rectangle
            name="Enterprise"
            color={Color.purple400}
            textColor={Color.gray0}
            img={<BusinessOutlinedIcon className={styles.enterpriseSvg} />}
          />

          <Text
            color={Color.gray300}
            type={TextTypes.TEXT_SM}
            weight={isEnterpriseSelected ? FontWeight.BOLD : FontWeight.REGULAR}
          >
            Enterprise
          </Text>
        </Row>
      )}

      <Col items="stretch">
        {portals.map((item) =>
          item.type === PortalTypes.Enterprise ? undefined : (
            <Row
              clickable
              gap={8}
              tabIndex={0}
              key={item.id}
              items="center"
              className={clsx(
                styles.subItemLink,
                selectedPortal?.id === item.id && styles.active
              )}
              onClick={() => onItemClick(item.id)}
              onKeyDown={(e) => onEnterPressed(e, () => onItemClick(item.id))}
            >
              <Avatar
                size="md"
                rectangle
                name={item.name}
                color={Color.blue300}
                textColor={Color.gray0}
              />

              <Text
                type={TextTypes.TEXT_SM}
                weight={
                  selectedPortal?.id === item.id
                    ? FontWeight.BOLD
                    : FontWeight.REGULAR
                }
                color={Color.gray300}
              >
                {item.name}
              </Text>
            </Row>
          )
        )}
      </Col>
    </Popover>
  )
}

export default CollapsedPortalPicker
