import { TableFilterType } from '@/types/enums/table'
import { IFilterItem } from '@/types/interfaces/table'
import {
  isVisitorSelectOptions,
  loadStatusSelectOptions,
  mismatchSelectOptions
} from '@/utils/mappers'
import {
  dwellToDateRangeFilter,
  listToArrayFilter,
  listToStringFilter
} from '@/utils/table/filterRequestFormatters'

const cargoAssetFilters = (accounts: string[]): IFilterItem[] => [
  {
    id: 'appointmentType',
    name: 'Visitor',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    requestFormatter: listToStringFilter,
    options: isVisitorSelectOptions
  },
  {
    id: 'accountName',
    name: 'Account',
    type: TableFilterType.List,
    placeholder: 'Search Account',
    requestFormatter: listToArrayFilter,
    options: accounts.map((account) => ({
      id: account,
      label: account
    }))
  },
  {
    id: 'loadStatus',
    name: 'Load Status',
    type: TableFilterType.List,
    placeholder: 'Search Status',
    requestFormatter: listToStringFilter,
    options: loadStatusSelectOptions
  },
  {
    id: 'dwell',
    name: 'Dwell',
    type: TableFilterType.Dwell,
    requestFormatter: dwellToDateRangeFilter
  },
  {
    id: 'mismatch',
    name: 'Mismatches',
    type: TableFilterType.List,
    placeholder: 'Search Value',
    requestFormatter: listToStringFilter,
    options: mismatchSelectOptions
  }
]

export default cargoAssetFilters
