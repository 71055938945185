import CloseIcon from '@mui/icons-material/Close'
import { Backdrop, Modal, Slide } from '@mui/material'
import clsx from 'clsx'
import { FC, PropsWithChildren, ReactNode, useState } from 'react'

import { Button, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'

import styles from './Modal.module.scss'

export interface ModalProps extends IModalWithCloseFn {
  title: string
  formMode?: boolean
  footer?: ReactNode
  footerLeft?: ReactNode
  header?: ReactNode
  className?: string
  bodyClassName?: string
  cancelButtonText?: string
  Navigation?: ReactNode
  size?: 'sm' | 'md' | 'lg'
  placement?: 'left' | 'right' | 'center' | 'fullScreen'
  hideCloseButtons?: boolean
  disableCloseOnBackdropClick?: boolean
}

const CustomModal: FC<PropsWithChildren & ModalProps> = (props) => {
  const {
    formMode,
    closeModal,
    className,
    bodyClassName,
    placement = 'center',
    footer,
    header,
    title,
    size = 'sm',
    footerLeft,
    cancelButtonText,
    Navigation,
    children,
    hideCloseButtons,
    disableCloseOnBackdropClick
  } = props

  const [open, setOpen] = useState<boolean>(true)

  const withNavigation = !!Navigation

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onClose={(
        _event: React.SyntheticEvent,
        reason: 'backdropClick' | 'escapeKeyDown'
      ) => {
        if (disableCloseOnBackdropClick && reason === 'backdropClick') return
        setOpen(false)
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Slide
        in={open}
        direction={placement === 'right' ? 'left' : 'up'}
        onExited={closeModal}
      >
        <div
          className={clsx(
            styles.box,
            styles[placement],
            styles[size],
            className
          )}
        >
          <Row
            items="center"
            gap={10}
            justify="between"
            className={clsx(styles.header, {
              [styles.formMode]: formMode,
              [styles.withNavigation]: withNavigation
            })}
          >
            <Text
              weight={FontWeight.SEMIBOLD}
              type={TextTypes.TEXT_XL}
              color={Color.gray700}
            >
              {title}
            </Text>

            <Row items="center" gap={10}>
              {header}
              {!hideCloseButtons && (
                <div
                  className={styles.closeButton}
                  tabIndex={0}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </div>
              )}
            </Row>
          </Row>

          {Navigation}

          <div
            className={clsx(
              styles.modalBody,
              bodyClassName,
              formMode && styles.formMode
            )}
          >
            {children}
          </div>

          <Row
            gap={8}
            items="center"
            justify={footerLeft ? 'between' : 'end'}
            className={styles.footer}
          >
            {footerLeft}

            <Row items="stretch" gap={8}>
              {!hideCloseButtons && (
                <Button type="outlined" onClick={handleClose}>
                  {cancelButtonText || 'Close'}
                </Button>
              )}

              {footer}
            </Row>
          </Row>
        </div>
      </Slide>
    </Modal>
  )
}

export default CustomModal
