import { FC } from 'react'

import { Col } from '@/components/atoms'
import {
  CargoAssetFormSection,
  FormCol,
  FormRow,
  SuggestedItems
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { FormSectionType } from '@/features/forms/enums'

interface IProps {
  isPresent?: boolean
  readOnly?: boolean
  collapsed?: boolean
  suggestedIds?: string[]
  onRemove?: () => void
  showCarrier?: boolean
  onAdd?: (type: FormSectionType) => void
}

const CheckInCargoAsset: FC<IProps> = (props) => {
  const {
    isPresent,
    suggestedIds = [],
    onRemove,
    onAdd,
    collapsed,
    readOnly = false,
    showCarrier = false
  } = props

  return (
    <CargoAssetFormSection
      isPresent={isPresent}
      onAdd={onAdd}
      onRemove={onRemove}
      collapsed={collapsed}
    >
      {({ isMinimized, isChassisVisible, isEmpty }) => (
        <FormCol>
          {!isMinimized && (
            <>
              <CargoAssetFormSection.Type readOnly={readOnly} />

              <Col gap={7}>
                <CargoAssetFormSection.OwnerId readOnly={readOnly} />
                <SuggestedItems
                  fieldId={FORM_IDS.CARGO_ASSET.OWNER_ID}
                  suggestedItems={suggestedIds}
                />
              </Col>

              {showCarrier && (
                <CargoAssetFormSection.AssetCarrier readOnly={readOnly} />
              )}
            </>
          )}

          <Col>
            <CargoAssetFormSection.LoadStatus readOnly={readOnly} />
            <CargoAssetFormSection.InspectionCompleted readOnly={readOnly} />
          </Col>

          {!isEmpty && (
            <CargoAssetFormSection.ShipmentNumber readOnly={readOnly} />
          )}

          {isChassisVisible && (
            <CargoAssetFormSection.ChassisId readOnly={readOnly} />
          )}

          {!isEmpty && (
            <Col items="stretch" gap={4}>
              <CargoAssetFormSection.SealNumbers readOnly={readOnly} />
              <CargoAssetFormSection.SealMatchesPW readOnly={readOnly} />
            </Col>
          )}

          {!isMinimized && (
            <FormRow>
              <CargoAssetFormSection.LicensePlateNumber readOnly={readOnly} />
              <CargoAssetFormSection.LicensePlateState readOnly={readOnly} />
            </FormRow>
          )}
        </FormCol>
      )}
    </CargoAssetFormSection>
  )
}

export default CheckInCargoAsset
