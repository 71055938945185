import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FC } from 'react'

import { Button, Col, Text } from '@/components/atoms'
import { Modal } from '@/components/organisms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'

interface Props extends IModalWithCloseFn {
  title: string
  onDelete: () => void
}

const DeleteFormSectionModal: FC<Props> = (props) => {
  const { title, closeModal, onDelete } = props

  return (
    <Modal
      title={`Delete ${title}`}
      placement="center"
      closeModal={closeModal}
      footer={
        <Button
          type="danger"
          startIcon={<DeleteOutlineIcon />}
          onClick={onDelete}
        >
          Delete
        </Button>
      }
    >
      <Col gap={7}>
        <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
          Are you sure you want to delete this {title}?
        </Text>

        <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
          Deleting will will erase all your progress and cannot be undone.
        </Text>
      </Col>
    </Modal>
  )
}

export default DeleteFormSectionModal
