import { baseApi, invalidateTagsOnSuccess, withParams } from '@/api'

import { HttpMethod } from '@/api/enums'
import {
  CreateGateTransactionRequest,
  CreateGateTransactionResponse,
  DeleteQueueEventRequest,
  FetchAccountsRequest,
  FetchAccountsResponse,
  FetchGatesRequest,
  FetchGatesResponse,
  FetchLanesRequest,
  FetchLanesResponse,
  FetchPresignedUrlRequest,
  FetchPresignedUrlResponse,
  FetchQueueEventsRequest,
  FetchQueueEventsResponse,
  GetCarrierRequest,
  GetCarrierResponse,
  GetCarriersListRequest,
  GetCarriersListResponse,
  GetClassAndFuelDetailsRequest,
  GetClassAndFuelDetailsResponse,
  GetVinRequest,
  GetVinResponse,
  UpdateGateTransactionRequest,
  UpdateGateTransactionResponse
} from '@/features/gate/api/types'
import { DefaultApiResponse } from '@/types/interfaces/api'
import endpoints from './endpoints'
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query'

export const gateApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchGates: builder.query<FetchGatesResponse, FetchGatesRequest>({
      query: ({ org_id, site_id }) => {
        return withParams(endpoints.GET_GATES, { org_id, site_id })
      }
    }),

    fetchLanes: builder.query<FetchLanesResponse, FetchLanesRequest>({
      query: ({ org_id, site_id, gate_id }) => {
        return withParams(endpoints.GET_LANES, { org_id, site_id, gate_id })
      }
    }),

    fetchAccounts: builder.query<string[], FetchAccountsRequest>({
      query: ({ org_id, site_id }) => ({
        url: withParams(endpoints.GET_ACCOUNTS, { org_id, site_id })
      }),

      keepUnusedDataFor: 300,

      transformResponse: (response: FetchAccountsResponse) =>
        response?.data?.accounts || []
    }),

    fetchQueueEvents: builder.query<
      FetchQueueEventsResponse,
      FetchQueueEventsRequest
    >({
      providesTags: ['Event'],
      query: ({ org_id, lane_id, ...params }) => ({
        url: withParams(endpoints.GET_QUEUE_EVENTS, {
          org_id,
          lane_id
        }),
        params
      }),
      transformResponse: (response: FetchQueueEventsResponse, meta: FetchBaseQueryMeta) => {
        return {
          ...response,
          status: meta.response?.status
        }
      }
    }),

    deleteQueueEvent: builder.mutation<
      DefaultApiResponse,
      DeleteQueueEventRequest
    >({
      invalidatesTags: invalidateTagsOnSuccess(['Event']),
      query: ({ reason, ...urlParams }) => ({
        url: withParams(endpoints.DELETE_QUEUE_EVENT, urlParams),
        params: { reason },
        method: HttpMethod.DELETE
      })
    }),

    getPresignedUrl: builder.query<string, FetchPresignedUrlRequest>({
      query: ({ org_id, site_id, event_id }) => ({
        url: withParams(endpoints.GET_PRESIGNED_URL, {
          org_id,
          site_id,
          event_id
        })
      }),
      keepUnusedDataFor: 600,

      transformResponse: (response: FetchPresignedUrlResponse) =>
        response?.data?.url || ''
    }),

    createGateTransaction: builder.mutation<
      CreateGateTransactionResponse,
      CreateGateTransactionRequest
    >({
      invalidatesTags: invalidateTagsOnSuccess(['Event']),
      query: ({ params, body }) => {
        return {
          url: withParams(endpoints.CREATE_GATE_TRANSACTION, params),
          method: HttpMethod.POST,
          body
        }
      }
    }),

    updateGateTransaction: builder.mutation<
      UpdateGateTransactionResponse,
      UpdateGateTransactionRequest
    >({
      query: ({ params, body }) => {
        return {
          url: withParams(endpoints.UPDATE_GATE_TRANSACTION, params),
          method: HttpMethod.PATCH,
          body
        }
      }
    }),

    getCarrier: builder.query<GetCarrierResponse, GetCarrierRequest>({
      query: ({ org_id, us_dot }) => {
        return {
          url: withParams(endpoints.GET_CARRIER, { org_id }),
          params: { us_dot }
        }
      }
    }),

    getCarriers: builder.query<GetCarriersListResponse, GetCarriersListRequest>(
      {
        query: ({ org_id, partial_name }) => {
          return {
            url: withParams(endpoints.GET_US_DOT, { org_id }),
            params: { partial_name }
          }
        }
      }
    ),

    getVin: builder.query<GetVinResponse, GetVinRequest>({
      query: ({ org_id, license_plate_number, state }) => {
        return {
          url: withParams(endpoints.GET_VIN_NUMBER, { org_id }),
          params: { license_plate_number, state }
        }
      }
    }),

    getClassAndFuel: builder.query<
      GetClassAndFuelDetailsResponse,
      GetClassAndFuelDetailsRequest
    >({
      query: ({ org_id, vin }) => {
        return {
          url: withParams(endpoints.GET_CLASS_AND_FUEL_DETAILS, { org_id }),
          params: { vin }
        }
      }
    })
  })
})

export const {
  useLazyFetchGatesQuery,
  useFetchLanesQuery,
  useFetchQueueEventsQuery,
  useFetchAccountsQuery,
  useGetPresignedUrlQuery,
  useLazyGetCarrierQuery,
  useLazyGetVinQuery,
  useLazyGetClassAndFuelQuery,
  useGetCarriersQuery,
  useGetCarrierQuery,
  useUpdateGateTransactionMutation,
  useDeleteQueueEventMutation,
  useCreateGateTransactionMutation
} = gateApi
