import { FC, KeyboardEvent } from 'react'

import CargoAssetIcon from '@/assets/icons/cargo-asset.svg?react'
import PowerUnitIcon from '@/assets/icons/power-unit.svg?react'
import { Col, Row, Text } from '@/components/atoms'
import { FormSectionType } from '@/features/forms/enums'
import { LaneDirection } from '@/features/gate/enums'
import { getLaneDirectionName } from '@/features/gate/utils'
import { Color } from '@/styles/palette'
import { FontWeight, KeyCode, TextAlign, TextTypes } from '@/types/enums/ui'

import styles from './SelectFirstSection.module.scss'
import { formSectionNameMapper } from '@/features/forms/utils'
import { onEnterPressed } from '@/utils/helpers'

interface Props {
  direction: LaneDirection
  onSelect: (type: FormSectionType) => void
}

const options = [
  {
    id: FormSectionType.PowerUnitAndDriver,
    label: formSectionNameMapper[FormSectionType.PowerUnitAndDriver],
    Icon: <PowerUnitIcon />
  },
  {
    id: FormSectionType.CargoAsset,
    label: formSectionNameMapper[FormSectionType.CargoAsset],
    Icon: <CargoAssetIcon />
  }
]

const SelectFirstSection: FC<Props> = (props) => {
  const { onSelect, direction } = props

  const directionName = getLaneDirectionName(direction).toLowerCase()

  return (
    <Col items="center" justify="center" gap={24} className={styles.wrapper}>
      <Text
        type={TextTypes.TEXT_LG}
        color={Color.gray700}
        align={TextAlign.CENTER}
      >
        Select the {directionName} detail you would like to add first
      </Text>

      <Row items="center" gap={16}>
        {options.map(({ id, label, Icon }) => (
          <Col
            key={id}
            gap={24}
            tabIndex={0}
            items="center"
            justify="center"
            className={styles.box}
            onClick={() => onSelect(id)}
            onKeyDown={(e) => onEnterPressed(e, () => onSelect(id))}
          >
            <Row items="center" justify="center" className={styles.iconWrapper}>
              {Icon}
            </Row>

            <Text
              type={TextTypes.TEXT_SM}
              weight={FontWeight.SEMIBOLD}
              color={Color.gray700}
            >
              {label}
            </Text>
          </Col>
        ))}
      </Row>
    </Col>
  )
}

export default SelectFirstSection
