import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Input, ReadOnlyFormValue } from '@/components/atoms'
import { SEAL_NUMBER_HELPER } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import { arrayToString } from '@/utils/table'

const SealNumbers: FC<ReadOnlyProps> = (props) => {
  const { readOnly, label = 'Seal #' } = props

  const { control, watch } = useFormContext()

  const sealNumbers = arrayToString(
    { sealNumbers: watch(FORM_IDS.CARGO_ASSET.SEAL_NUMBERS) },
    'sealNumbers'
  )

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={sealNumbers} />
  }

  return (
    <Controller
      name={FORM_IDS.CARGO_ASSET.SEAL_NUMBERS}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          fullWidth
          type="multiple"
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message || SEAL_NUMBER_HELPER}
        />
      )}
    />
  )
}

export default SealNumbers
