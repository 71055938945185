const userManagementEndpoints = {
  GET_USERS: '/organizations/:orgId/members',
  GET_INVITES: '/organizations/:orgId/invites',
  GET_USER_PROFILE: '/organizations/:orgId/members/:userId/profile',
  GET_USER_SITES: '/organizations/:orgId/members/:userId/sites',
  GET_USER_ORG_ROLES: '/organizations/:orgId/members/:userId/roles',
  GET_USER_SITE_ROLES:
    '/organizations/:orgId/members/:userId/sites/:siteId/roles',

  SEND_INVITE: '/organizations/:orgId/invites',
  UPDATE_USER_PROFILE: '/organizations/:orgId/members/:userId/profile',
  UPDATE_USER_ENTERPRISE_ROLES: '/organizations/:orgId/members/:userId/roles',
  UPDATE_USER_SITE_ROLES:
    '/organizations/:orgId/members/:userId/sites/:siteId/roles',
  DISABLE_USER: '/organizations/:orgId/members/:userId/disable',
  ENABLE_USER: '/organizations/:orgId/members/:userId/enable',

  DELETE_USER: '/organizations/:orgId/members/:userId',
  DELETE_INVITE: '/organizations/:orgId/invites/:inviteId',

  ORGANIZATION_AUTHENTICATION: '/organizations/:orgId/authentication'
}

export default userManagementEndpoints
