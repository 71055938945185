import { FC } from 'react'

import { InfoOutlined } from '@mui/icons-material'
import styles from './SsoRequiredBadge.module.scss'

import { Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextAlign, TextTypes } from '@/types/enums/ui'
import { Alert } from '@mui/material'

interface IProps {}

const SsoRequiredBadge: FC<IProps> = (props) => {
  return (
    <Alert
      severity="info"
      icon={<InfoOutlined className={styles.infoIcon} />}
      className={styles.ssoAlertContainer}
    >
      <Col gap={4} className={styles.ssoBadgeContainer}>
        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.REGULAR}
          align={TextAlign.CENTER}
          color={Color.gray800}
        >
          Okta SSO required
        </Text>
        <Text
          type={TextTypes.TEXT_XS}
          weight={FontWeight.REGULAR}
          align={TextAlign.CENTER}
          color={Color.gray600}
        >
          Users will only be able to log in using Okta SSO.
        </Text>
      </Col>
    </Alert>
  )
}

export default SsoRequiredBadge
