import React, { FC } from 'react'

import { Button, Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextAlign, TextTypes } from '@/types/enums/ui'
import clsx from 'clsx'

import styles from './BaseEmptyState.module.scss'

export interface IBaseEmtpyState {
  Icon?: React.ReactNode
  primaryText: string
  descriptionText: string
  buttonText?: string
  buttonAction?: () => void
}

const BaseEmptyState: FC<IBaseEmtpyState> = (props) => {
  const { Icon, primaryText, descriptionText, buttonText, buttonAction } = props

  return (
    <Col
      items="center"
      justify="center"
      gap={24}
      className={clsx(styles.baseEmptyState)}
    >
      {Icon}

      <Col items="center" justify="center" gap={16}>
        <Text
          type={TextTypes.TEXT_MD}
          align={TextAlign.CENTER}
          color={Color.gray700}
        >
          {primaryText}
        </Text>

        <Text
          type={TextTypes.TEXT_SM}
          align={TextAlign.CENTER}
          color={Color.gray600}
        >
          {descriptionText}
        </Text>
      </Col>

      {buttonText && buttonAction && (
        <Button type="outlined" onClick={buttonAction}>
          {buttonText}
        </Button>
      )}
    </Col>
  )
}

export default BaseEmptyState
