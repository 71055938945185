import { gql } from '@/__generated__'

export const GetISRReportsQuery = gql(`
  query ListIsrReports($input: ListISRRecordsInput!) {
    listISRRecords(input: $input) {
      ISRRecords {
        organizationId,
        siteId,
        
        powerUnitLicensePlateNumber,
        powerUnitLicensePlateState,
        
        ciEventId,
        ciCorrelationId,
        ciEventUser,
        ciEventTime,
        ciMetadata {
            ...gateTransactionMetadata
        },
        ciLaneDisplayName,
        
        coEventId,
        coCorrelationId,
        coEventUser,
        coEventTime,
        coMetadata {
            ...gateTransactionMetadata
        },
        coLaneDisplayName,
        
        dwellTime
      }

      pagination {
        ...paginationResponse
      }
    }
  }
`)
