export enum WeightClasses {
  Class2b7 = '2b-7',
  Class8 = '8'
}

export enum FuelTypes {
  Gas = 'G',
  Diesel = 'D',
  CNG = 'N',
  LNG = 'L',
  HFC = 'R',
  Electric = 'E'
}

export enum EmissionType {
  E = 'E',
  NZE = 'NZE',
  ZE = 'ZE'
}

export enum AppointmentTypes {
  Scheduled = 'scheduled',
  Visitor = 'visitor'
}

export enum LoadTypes {
  Empty = 'empty',
  Loaded = 'loaded',
  Partial = 'partially_loaded'
}

export enum CargoAssetTypes {
  Trailer = 'trailer',
  OceanContainer = 'ocean_container',
  IntermodalContainer = 'intermodal_container'
}

export enum PowerUnitTypes {
  Tractor = 'tractor',
  BoxTruckOrSprinterVan = 'box_truck_or_sprinter_van',
  PersonalVehicle = 'personal_vehicle'
}
