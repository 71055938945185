import { useState } from 'react'
import { UseFormReset } from 'react-hook-form'

import { FORM_IDS } from '@/features/forms/constants'
import { FormSectionType } from '@/features/forms/enums'
import { GateQueueEvent } from '@/features/gate/types'
import {
  GateTransactionSchemaType,
  isCargoAssetPresent,
  isPowerUnitPresent
} from '@/features/gate/utils'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import { GateTransaction } from '@/__generated__/graphql'

export type SectionPresent = Record<FormSectionType, boolean>

const useFormSectionsPresent = (
  item: GateQueueEvent | GateTransaction | undefined
) => {
  const [isSectionPresent, setSectionPresent] = useState<SectionPresent>(
    () => ({
      [FormSectionType.PowerUnitAndDriver]: isPowerUnitPresent(item),
      [FormSectionType.CargoAsset]: isCargoAssetPresent(item)
    })
  )

  // Ensures that active sections are displayed at the top of the form while
  // keeping the "Add New Section" button at the bottom.
  const sortValue = (value: SectionPresent) => {
    const sortedValue = Object.entries(value).sort(
      ([, a], [, b]) => Number(b) - Number(a)
    )

    return Object.fromEntries(sortedValue) as SectionPresent
  }

  const selectSection = (type: FormSectionType) => {
    setSectionPresent((prev) => sortValue({ ...prev, [type]: true }))
  }

  const removePowerUnitAndDriver = (
    reset: UseFormReset<GateTransactionSchemaType>
  ) => {
    setSectionPresent((prev) =>
      sortValue({ ...prev, [FormSectionType.PowerUnitAndDriver]: false })
    )

    reset((prev: GateTransactionSchemaType) => ({
      ...prev,
      [FORM_IDS.GENERAL.APPOINTMENT_TYPE]: AppointmentTypes.Scheduled,

      [FORM_IDS.POWER_UNIT.TYPE]: undefined,
      [FORM_IDS.POWER_UNIT.LPN]: '',
      [FORM_IDS.POWER_UNIT.LPN_STATE]: undefined,
      [FORM_IDS.POWER_UNIT.VIN]: '',
      [FORM_IDS.POWER_UNIT.USDOT]: '',
      [FORM_IDS.POWER_UNIT.OWNER_ID]: '',
      [FORM_IDS.POWER_UNIT.CARRIER]: '',
      [FORM_IDS.POWER_UNIT.WEIGHT_CLASS]: undefined,
      [FORM_IDS.POWER_UNIT.FUEL_TYPE]: undefined,
      [FORM_IDS.POWER_UNIT.ACCOUNT]: []
    }))
  }

  const removeCargoAsset = (reset: UseFormReset<GateTransactionSchemaType>) => {
    setSectionPresent((prev) =>
      sortValue({ ...prev, [FormSectionType.CargoAsset]: false })
    )

    reset((prev: GateTransactionSchemaType) => ({
      ...prev,

      [FORM_IDS.CARGO_ASSET.TYPE]: undefined,
      [FORM_IDS.CARGO_ASSET.LPN]: '',
      [FORM_IDS.CARGO_ASSET.LPN_STATE]: undefined,
      [FORM_IDS.CARGO_ASSET.OWNER_ID]: '',
      [FORM_IDS.CARGO_ASSET.CARRIER]: '',
      [FORM_IDS.CARGO_ASSET.SHIPMENT_NUMBER]: '',
      [FORM_IDS.CARGO_ASSET.SEAL_NUMBERS]: [],
      [FORM_IDS.CARGO_ASSET.CHASSIS_ID]: '',
      [FORM_IDS.CARGO_ASSET.LOAD_STATUS]: undefined,
      [FORM_IDS.CARGO_ASSET.SEAL_MATCHES_PW]: undefined,
      [FORM_IDS.CARGO_ASSET.INSPECTION_COMPLETED]: undefined
    }))
  }

  return {
    selectSection,
    removeCargoAsset,
    removePowerUnitAndDriver,

    isSectionPresent,
    isEmptyForm:
      !isSectionPresent.powerUnitAndDriverDetails &&
      !isSectionPresent.cargoAssetDetails
  }
}

export default useFormSectionsPresent
