import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Input, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { ReadOnlyProps } from '@/types/interfaces/ui'

interface IProps {
  required?: boolean
}

const UsDOT: FC<IProps | ReadOnlyProps> = (props) => {
  const { required } = props as IProps
  const { readOnly, label = 'US DOT #' } = props as ReadOnlyProps

  const { control, watch } = useFormContext()

  const usDOT = watch(FORM_IDS.POWER_UNIT.USDOT)

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={usDOT} />
  }

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.USDOT}
      control={control}
      defaultValue=""
      render={({ field, fieldState }) => (
        <Input
          {...field}
          required={required}
          fullWidth
          digitsOnly
          type="number"
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default UsDOT
