import { StringOperator } from '@/__generated__/graphql'
import { DetectionType } from '@/features/gate/enums'

const referenceIdToStringFilter = (values: string[]) => {
  // No need to apply filter if both selected
  if (values.length === Object.values(DetectionType).length)
    return {
      operator: StringOperator.StartsWith,
      value: ''
    }

  // Detections contain referenceId
  if (values.includes(DetectionType.Detection)) {
    return {
      operator: StringOperator.NotEquals,
      value: ''
    }
  }

  // Manual entries don't contain referenceId
  return {
    operator: StringOperator.Equals,
    value: ''
  }
}

export default referenceIdToStringFilter
