import { configureStore } from '@reduxjs/toolkit'
import { useDispatch as useDispatchReact, useSelector } from 'react-redux'

import { userStoreReducer } from '@/features/auth/store'
import { baseApi } from '@/api'
import { snackbarStoreReducer } from '@/features/snackbars-queue/store'
import { errorMiddleware } from '@/api/middleware'

export const store = configureStore({
  reducer: {
    user: userStoreReducer,
    snackbar: snackbarStoreReducer,
    [baseApi.reducerPath]: baseApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      baseApi.middleware,
      errorMiddleware
    )
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useDispatch = useDispatchReact.withTypes<AppDispatch>()
export const useStore = useSelector.withTypes<RootState>()
