import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { FC } from 'react'

import { Row, Tooltip } from '@/components/atoms'
import {
  IHighOrderColumn,
  ITableColumn,
  TableSortValue
} from '@/types/interfaces/table'

import styles from './TableHeader.module.scss'
import { TableSortDirection } from '@/types/enums/table'

interface IProps {
  highOrderCols?: IHighOrderColumn[]
  cols: ITableColumn[]
  sortBy: TableSortValue | undefined
  handleSort: (property: string) => void
}

const TableHeader: FC<IProps> = (props) => {
  const { highOrderCols, cols, sortBy, handleSort } = props

  const renderTooltip = (tooltip: string) => (
    <Tooltip placement="bottom" show tooltip={tooltip}>
      <ErrorOutlineIcon className={styles.tooltipIcon} />
    </Tooltip>
  )

  return (
    <TableHead>
      {!!highOrderCols?.length && (
        <TableRow>
          {highOrderCols.map((column) => (
            <TableCell
              key={column.id}
              colSpan={column.colSpan}
              variant="head"
              align="left"
            >
              {column.title}
            </TableCell>
          ))}
        </TableRow>
      )}

      <TableRow>
        {cols.map((column) => {
          const sortId = column.sortId || column.id
          const isSortedByCurrentColumn = sortBy?.field === sortId

          return column.sortable ? (
            <TableCell
              key={column.id}
              variant="head"
              align="left"
              sortDirection={
                isSortedByCurrentColumn ? sortBy?.direction : false
              }
            >
              <TableSortLabel
                active={isSortedByCurrentColumn}
                direction={
                  isSortedByCurrentColumn
                    ? sortBy?.direction
                    : TableSortDirection.Asc
                }
                onClick={() => handleSort(sortId)}
              >
                {column.title}

                {column.tooltip && renderTooltip(column.tooltip)}

                {isSortedByCurrentColumn ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortBy?.direction === TableSortDirection.Desc
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={column.id} variant="head" align="left">
              <Row items="center">
                {column.title}

                {column.tooltip && renderTooltip(column.tooltip)}
              </Row>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
