import { LaneDirection } from '@/features/gate/enums'

const getLaneDirectionName = (
  direction: LaneDirection,
  oppositeDirection?: boolean
) => {
  const isArriving = direction === LaneDirection.Arriving

  if (oppositeDirection) return isArriving ? 'Check-Out' : 'Check-In'

  return isArriving ? 'Check-In' : 'Check-Out'
}

export default getLaneDirectionName
