import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Autocomplete, Chip, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { useFetchAccountsQuery } from '@/features/gate/api'
import { useStore } from '@/store'
import { ReadOnlyProps } from '@/types/interfaces/ui'

interface IProps {
  siteId: string
}

const Account: FC<IProps | ReadOnlyProps> = (props) => {
  const { siteId } = props as IProps
  const { readOnly, label = 'Account' } = props as ReadOnlyProps

  const orgId = useStore((store) => store.user.org?.organization_id || '')
  const { control, watch } = useFormContext()

  const { data: accounts = [] } = useFetchAccountsQuery(
    {
      site_id: siteId!,
      org_id: orgId!
    },
    { skip: readOnly }
  )

  const account = watch(FORM_IDS.POWER_UNIT.ACCOUNT)?.toString()

  if (readOnly) return <ReadOnlyFormValue title={label} value={account} />

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.ACCOUNT}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          multiple
          name={field.name}
          label={label}
          freeSolo={false}
          value={field.value || []}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          renderTags={(savedValues, getTagProps) =>
            savedValues.map((item, index) => (
              <Chip
                key={`${index}-${item}`}
                withBorder
                size="sm"
                type="default"
                label={item}
                className="!tw-mr-[5px]"
                onDelete={getTagProps({ index }).onDelete}
              />
            ))
          }
          onChange={(value: string[] | null) => {
            field.onChange(value || [])
          }}
          isOptionEqualToValue={(option: string, value: string[]) =>
            value?.includes(option)
          }
          options={accounts}
        />
      )}
    />
  )
}

export default Account
