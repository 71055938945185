import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import {
  DriverDetailsFormSection,
  FormCol,
  FormRow,
  PowerUnitFormSection
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { FormSectionType } from '@/features/forms/enums'
import { ToggleDetails } from '@/features/gate'
import { GateTransactionSchemaType } from '@/features/gate/utils'
import {
  AppointmentTypes,
  FuelTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'
import { GateTransaction } from '@/__generated__/graphql'

interface IProps {
  siteId: string
  isManual: boolean
  isPresent: boolean
  onAdd: (type: FormSectionType) => void
  onRemove?: () => void
}

const CheckOutPowerUnit: FC<IProps> = (props) => {
  const { siteId, isPresent, isManual, onRemove, onAdd } = props

  const { trigger, reset } = useFormContext<GateTransactionSchemaType>()

  const [selectedEventId, setSelectedEventId] = useState<string | undefined>(
    undefined
  )

  const populatePowerUnitFields = (
    selectedEvent: GateTransaction | undefined
  ) => {
    setSelectedEventId(selectedEvent?.id)

    const { metadata } = selectedEvent || {}
    const {
      appointmentType,

      powerUnitType,
      powerUnitOwnerId,
      powerUnitLicensePlateNumber,
      powerUnitLicensePlateState,
      powerUnitCarrierUsdot,
      powerUnitCarrierName,
      powerUnitWeightClass,
      powerUnitFuelType,
      powerUnitVin,
      accountName,

      driverFirstName,
      driverLastName,
      driverLicenseNumber,
      driverLicenseState,
      driverPhoneNumber
    } = metadata || {}

    reset(
      (prev) => ({
        ...prev,

        [FORM_IDS.GENERAL.APPOINTMENT_TYPE]: Object.values(
          AppointmentTypes
        ).includes(appointmentType as AppointmentTypes)
          ? (appointmentType as AppointmentTypes)
          : AppointmentTypes.Scheduled,

        [FORM_IDS.POWER_UNIT.TYPE]: powerUnitType as PowerUnitTypes,
        [FORM_IDS.POWER_UNIT.OWNER_ID]: powerUnitOwnerId || '',
        [FORM_IDS.POWER_UNIT.LPN]: powerUnitLicensePlateNumber || '',
        [FORM_IDS.POWER_UNIT.LPN_STATE]: powerUnitLicensePlateState || '',
        [FORM_IDS.POWER_UNIT.USDOT]: powerUnitCarrierUsdot || '',
        [FORM_IDS.POWER_UNIT.CARRIER]: powerUnitCarrierName || '',
        [FORM_IDS.POWER_UNIT.WEIGHT_CLASS]:
          powerUnitWeightClass as WeightClasses,
        [FORM_IDS.POWER_UNIT.FUEL_TYPE]: powerUnitFuelType as FuelTypes,
        [FORM_IDS.POWER_UNIT.VIN]: powerUnitVin || '',
        [FORM_IDS.POWER_UNIT.ACCOUNT]: accountName || [],

        [FORM_IDS.DRIVER_DETAILS.FIRST_NAME]: driverFirstName || '',
        [FORM_IDS.DRIVER_DETAILS.LAST_NAME]: driverLastName || '',
        [FORM_IDS.DRIVER_DETAILS.DRIVER_ID]: driverLicenseNumber || '',
        [FORM_IDS.DRIVER_DETAILS.LICENSE_STATE]: driverLicenseState || '',
        [FORM_IDS.DRIVER_DETAILS.PHONE]: driverPhoneNumber || ''
      }),
      { keepDirtyValues: true, keepSubmitCount: true }
    )

    // Revalidate fields which are not read-only to make sure errors are cleared
    // after selecting a new value
    trigger([
      FORM_IDS.POWER_UNIT.OWNER_ID as keyof GateTransactionSchemaType,
      FORM_IDS.POWER_UNIT.LPN as keyof GateTransactionSchemaType
    ])
  }

  return (
    <PowerUnitFormSection
      isPresent={isPresent}
      onAdd={onAdd}
      onRemove={onRemove}
    >
      <FormCol>
        <PowerUnitFormSection.VisitorToggle readOnly />

        <FormRow columnOnMobile>
          <FormRow>
            <PowerUnitFormSection.LicensePlateNumber
              autoComplete
              siteId={siteId}
              selectedEventId={selectedEventId}
              onOptionSelect={populatePowerUnitFields}
            />
            <PowerUnitFormSection.LicensePlateState readOnly />
          </FormRow>

          <PowerUnitFormSection.VinNumber readOnly allowApiCalls={false} />
        </FormRow>

        <FormRow>
          <PowerUnitFormSection.UsDOT readOnly />
          <PowerUnitFormSection.OperatingCarrier readOnly />
        </FormRow>

        <ToggleDetails>
          <FormCol>
            <FormRow>
              <PowerUnitFormSection.Type readOnly />
              <PowerUnitFormSection.OwnerId readOnly />
            </FormRow>

            <FormRow>
              <PowerUnitFormSection.WeightClass readOnly />
              <PowerUnitFormSection.FuelType readOnly />
            </FormRow>

            <FormRow>
              <PowerUnitFormSection.Account readOnly />
            </FormRow>
          </FormCol>
        </ToggleDetails>

        {isManual && (
          <DriverDetailsFormSection title="Driver" withoutWrapper readOnly />
        )}
      </FormCol>
    </PowerUnitFormSection>
  )
}

export default CheckOutPowerUnit
